import React, { FC } from "react";
import Button from "../../shared/Button/Button";
import { useTranslation } from "react-i18next";

interface Props {
  onClick?: () => void;
}

const ButtonSubmit: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Button
      onClick={onClick}
      className="md:h-[100px] md:w-[200px] w-full rounded-full bg-white backgroundHoverJogga flex items-center justify-center text-black font-bold focus:outline-none"
    >
      <span className="mr-3 text-lg">{t("home.searchInput")}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </Button>
  );
};

export default ButtonSubmit;
