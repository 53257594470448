import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { NearbyLocations } from "../../containers/LocationDetailsPage/types";
import { useTranslation } from "react-i18next";
import Badge from "shared/Badge/Badge";

export interface LocationCardHProps {
  className?: string;
  location: NearbyLocations;
}

const LocationCardH: FC<LocationCardHProps> = ({
  className = "",
  location,
}) => {
  const { t } = useTranslation();
  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-48 ">
        <GallerySlider
          ratioClass="aspect-w-5 aspect-h-5"
          galleryImgs={[location?.imageUrl]}
          uniqueID={`StayCardH_${location?._id}`}
          // href={href}
        />
        {/* <BtnLikeIcon
          isLiked={location.isFavorite}
          className="absolute right-3 top-3"
        /> */}
        {/*{saleOff && <SaleOffBadge className="absolute left-3 top-3" />}*/}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div>
        <div className="space-y-3 bold  justify-end flex">
          <div>
            <div className="hidden sm:block w-50 border-b border-neutral-100 dark:border-neutral-800 my-4 "></div>
            {t("ListingLocationsCityPage.cardSeeDetails")}
            <div className="w-50 border-b border-neutral-100 dark:border-neutral-800 my-4 flex"></div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {/*{isAds && <Badge name="ADS" color="green" />}*/}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{location.name}</span>
            </h2>
          </div>
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>{location.address}</span>
          </div>
          {location.isBookable ? (
            <Badge name={t("locationCard.isBookable")} color="green" />
          ) : (
            <Badge name={t("locationCard.noIssBookable")} color="gray" />
          )}
          {location.phone ? (
            <div className="text-sm text-neutral-500 dark:text-neutral-400">
              <span>Telefon: {location.phone}</span>
            </div>
          ) : null}
        </div>

        {renderTienIch()}

        {/*<div className="flex justify-between items-end">*/}
        {/*  /!*<StartRating reviewCount={reviewCount} point={reviewStart} />*!/*/}
        {/*  <span className="text-base font-semibold text-secondary-500">*/}
        {/*    /!*{price}*!/*/}
        {/*    {` `}*/}
        {/*    <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">*/}
        {/*      /night*/}
        {/*    </span>*/}
        {/*  </span>*/}
        {/*</div>*/}
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      {/*<Link to={`/${data.seoUrl ? data.seoUrl : data._id}`} className="absolute inset-0"></Link>*/}
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default LocationCardH;
