import api from "api";
import { PLAYERS } from "models/apiRoutes";
import { useTranslation } from "react-i18next";
import urlParamReplacer from "utils/urlParamReplacer";
interface LikeSaveBtnsProps {
  isFavorite: boolean;
  locationId: string;
  sportId: string;
  reFetch: () => void;
}

const LikeSaveBtns = ({
  isFavorite,
  locationId,
  sportId,
  reFetch,
}: LikeSaveBtnsProps) => {
  const { t } = useTranslation();
  const saveFavoriteAddres = async () => {
    if (isFavorite) {
      await api.delete(
        urlParamReplacer(PLAYERS.FAVORITE_ADDRESS_ADDRESS_ID, locationId)
      );
    } else {
      await api.post(PLAYERS.FAVORITE_ADDRESS, {
        locationId,
        sportId,
      });
    }

    reFetch();
  };
  return (
    <div className="flow-root">
      <div
        className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5 justify-center"
        onClick={() => saveFavoriteAddres()}
      >
        <span
          className={`py-1.5 px-3 flex ${
            isFavorite ? "bg-green-400" : "bg-neutral-100"
          } rounded-lg hover:bg-neutral-200 cursor-pointer ml-2 text-neutral-800 text-sm font-medium`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">
            {t("LocationDetailsPage.saveFavorite")}
          </span>
        </span>
      </div>
    </div>
  );
};

export default LikeSaveBtns;
