import leftImg from "images/groups/group1.png";
import centerImg from "images/groups/group3.png";
import rightImg from "images/groups/group4.png";
import rightImg2 from "images/groups/group5.png";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";

export interface PageGroupsProps {
  className?: string;
}
const PageGroups: FC<PageGroupsProps> = ({ className = "" }) => {
  return (
      <div
          className={`nc-PageAbout overflow-hidden relative ${className}`}
          style={{
            backgroundColor: "#075de9"
          }}
          data-nc-id="PageAbout"
      >
        <Helmet>
          <title>Vrei să-ți găsești partener de sport? </title>
          <meta
              name="description"
              content="Jogga este aplicația care te ajută să faci sport. Creează și alătură-te jocurilor!"
          />
        </Helmet>

        <div className="containerGlass">
          <div className="cardGlass">
            <h1 className="titleGlass">Vrei să-ți găsești partener de sport?</h1>
            <p className="subtitleGlass">Jogga este aplicația care te ajută să faci sport. Creează și alătură-te jocurilor!</p>
            <ButtonPrimary onClick={() => window.open('https://play.jogga.app/')}>Descarca aplicatia</ButtonPrimary>
          </div>
          <div className="blob"></div>
        </div>
      </div>
  );
};

export default PageGroups;
