import React, { FC, ReactNode } from "react";
import SearchForm from "components/HeroSearchForm/SearchForm";
import { LocationsCity } from "../../containers/PageHome/PageHome";
import { NearbyLocations } from "../../containers/LocationDetailsPage/types";
import { useParams } from "react-router-dom";

export interface SectionHeroArchivePageProps {
  className?: string;
  cityName?: string;
  countryName?: string;
  listingType?: ReactNode;
  rightImage: string;
  locationsCity?: LocationsCity[];
  locations?: NearbyLocations[];
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  rightImage,
  cityName,
  countryName,
  locationsCity,
  locations,
}) => {
  let { sportName } = useParams();
  return (
    <div>
      <div className="w-full backgroundJogga">
        <SearchForm
          cityName={cityName}
          locationsCity={locationsCity}
          sportId={sportName ? sportName : "all"}
        />
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
