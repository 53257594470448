import { Transition } from "@headlessui/react";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import LocationCard from "components/LocationCard/LocationCard";
import { CarDataType, ExperiencesDataType, StayDataType } from "data/types";
import React, { FC, Fragment } from "react";
import { useState } from "react";
import { NearbyLocations } from "../../containers/LocationDetailsPage/types";
import LocationMarker from "./LocationMarker";

export interface AnyReactComponentProps {
  className?: string;
  listing?: NearbyLocations;
  experiences?: ExperiencesDataType;
  car?: CarDataType;
  isSelected?: boolean;
  lat: number;
  lng: number;
}

const AnyReactComponent: FC<AnyReactComponentProps> = ({
  className = "",
  listing,
  car,
  experiences,
  isSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`nc-AnyReactComponent relative  ${className}`}
      data-nc-id="AnyReactComponent"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {listing ? (
        <LocationMarker
          isSelected={isSelected}
          lat={listing.position?.coordinates[1]}
          lng={listing?.position?.coordinates[0]}
        />
      ) : null}

      {/*{listing?.price || experiences?.price || car?.price}*/}

      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute z-50 bottom-full pb-3 -left-12 w-[260px] aspect-w-1">
          {listing && (
            <LocationCard size="small" data={listing} className="shadow-2xl" />
          )}
        </div>
      </Transition>
    </div>
  );
};

export default AnyReactComponent;
