import { FC } from "react";
import { StarIcon } from "@heroicons/react/24/solid";
import { CardProp } from "containers/LocationDetailsPage/types";

export interface CardUserProp {
  className?: string;
  cardData: CardProp;
  selectedCard: string;
  setPayemntCard: (cardId: string) => void;
}

const CardUser: FC<CardUserProp> = ({
  className = "",
  cardData,
  selectedCard,
  setPayemntCard,
}) => {
  return (
    <button
      onClick={() => setPayemntCard(cardData._id)}
      className={`nc-CardAuthorBox ${
        selectedCard ? "backgroundJogga text-white" : ""
      } relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardAuthorBox"
    >
      <div className="mt-3">
        <h2 className={`text-base font-medium`}>
          <span className="line-clamp-1">Card: {cardData.cardPlaceholder}</span>
        </h2>
      </div>
      {/* <div className="py-2 px-5 mt-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
        <span className="text-xs font-medium pt-[1px]">{cardData.default}</span>
        <StarIcon className="w-5 h-5 text-amber-500 ml-2 " />
      </div> */}
    </button>
  );
};

export default CardUser;
