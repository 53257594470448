import { FC, useEffect, useState } from "react";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface HeaderFilterProps {
  tabActive: { name: string; key: string };
  tabs: { name: string; key: string }[];
  heading: ReactNode;
  subHeading?: ReactNode;
  onClickTab: (item: { key: string; name: string }) => void;
  variant?: string;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = "",
  heading,
  onClickTab,
  variant,
}) => {
  const { t } = useTranslation();
  const [tabActiveState, setTabActiveState] = useState(tabActive);

  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (item: { key: string; name: string }) => {
    onClickTab && onClickTab(item);
    setTabActiveState(item);
  };

  return (
    <div className="flex flex-col mb-8 relative">
      {variant === "small" ? (
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {t("HeaderFilter.selectFiled")}
        </span>
      ) : (
        <>{heading && <Heading desc={subHeading}>{heading}</Heading>}</>
      )}

      <div className="flex items-center justify-between">
        <Nav
          className="sm:space-x-2 mt-1"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
          {tabs.map((item, index) => (
            <NavItem
              key={index}
              isActive={tabActiveState.key === item.key}
              onClick={() => handleClickTab(item)}
            >
              {t(item.name)}
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default HeaderFilter;
