import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import NcImage from "../../shared/NcImage/NcImage";
import Football from "../../images/sports/categ-1.png";
import Tennis from "../../images/sports/categ-3.png";
import TableTennis from "../../images/sports/categ-4.png";
import AllSports from "../../images/sports/all-categ.png";
import ButtonSubmit from "./ButtonSubmit";
import ArrowDown from "./ArrowDown";

const Assets: any = {
  all: AllSports,
  football: Football,
  tennis: Tennis,
  tableTennis: TableTennis,
}
export interface SportSelectProps {
  selectedValue: { name: string; key: string },
  values: { name: string; key: string }[];
  onChange: (data: { name: string; key: string }) => void;
  fieldClassName?: string;
  className?: string;
  disabled?: boolean;
  onPresSubmit?: () => void;
  hasButtonSubmit?: boolean;
}

const FieldSelect: FC<SportSelectProps> = ({
  selectedValue,
  values,
  disabled = false,
  onPresSubmit,
  hasButtonSubmit,
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
}) => {
  const getFieldName = (selectedValue: any) => {
    const filedFind = values.find(field => field.key === selectedValue)
    if(filedFind){
      return filedFind.name
    }
    return ""
  }
  return (
    <Popover className={`flex relative ${className}`}>
      {({ open, close }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none cursor-pointer`}
          >
            <Popover.Button
              className={`flex-1 flex text-left items-center ${fieldClassName} space-x-3 justify-center align-middle`}
              disabled={disabled}
              onClick={() => document.querySelector("html")?.click()}
            >
              <div
                  className={`flex-1 flex relative ${fieldClassName} items-center space-x-2 cursor-pointer pt-3`}
              >
                <div className="text-zinc-50 ">
                  <svg fill="#fff" version="1.1" width="25px" height="25px" viewBox="0 0 980 980" >
                      <g>
                          <g>
                              <path d="M200.563,980h159.606h59.98h139.701h59.98h159.605c8.283,0,15-6.716,15-15V15c0-8.284-6.717-15-15-15H619.833h-59.98
                                  H420.151h-59.98H200.563c-8.284,0-15,6.716-15,15v950C185.563,973.284,192.279,980,200.563,980z M544.853,950H435.151v-39.851
                                  h109.7V950H544.853z M604.833,950h-29.98v-54.851c0-8.284-6.717-15-15-15H420.151c-8.284,0-15,6.716-15,15V950h-29.98v-99.831
                                  h229.663V950z M426.47,820.169c16.344-17.198,39.234-27.319,63.531-27.319c24.558,0,47.33,10.081,63.605,27.319H426.47
                                  L426.47,820.169z M435.151,30h109.7v39.851h-109.7V30z M375.17,30h29.98v54.851c0,8.284,6.716,15,15,15h139.7
                                  c8.285,0,15-6.716,15-15V30h29.98v99.831H375.17V30z M552.646,159.831c-16.203,16.624-38.566,26.312-62.644,26.312
                                  c-23.822,0-46.298-9.722-62.572-26.312H552.646z M215.563,30h129.606v114.831c0,8.284,6.716,15,15,15h29.468
                                  c21.309,34.846,59.347,56.312,100.363,56.312c41.428,0,79.073-21.357,100.366-56.312h29.465c8.285,0,15-6.716,15-15V30h129.605
                                  v445H606.628c-7.389-57.776-56.873-102.579-116.626-102.579c-59.753,0-109.237,44.803-116.626,102.579H215.563V30z M403.712,475
                                  c7.135-41.17,43.111-72.579,86.289-72.579c43.177,0,79.154,31.41,86.289,72.579H403.712z M576.29,505
                                  c-7.135,41.17-43.111,72.579-86.289,72.579c-43.178,0-79.154-31.409-86.289-72.579H576.29z M215.563,505h157.811
                                  c7.389,57.776,56.874,102.579,116.626,102.579c59.752,0,109.239-44.803,116.627-102.579h157.811v445H634.833V835.169
                                  c0-8.284-6.717-15-15-15h-28.844c-21.18-35.556-59.16-57.319-100.988-57.319c-41.411,0-79.786,21.881-100.971,57.319h-28.86
                                  c-8.284,0-15,6.716-15,15V950H215.563V505z"/>
                          </g>
                      </g>
                      </svg>
                </div>
                <div className="flex-grow">
                <span className="block mt-1 text-sm text-zinc-50 leading-none font-light">
                    Teren
                </span>
                  <span className="block xl:text-base font-semibold text-zinc-50 ">
                    {selectedValue.name}
                  </span>
                </div>
                <ArrowDown />
              </div>
            </Popover.Button>
             {/*BUTTON SUBMIT OF FORM*/}
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit onClick={onPresSubmit} />
              </div>
            )}

          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 z-10 w-full sm:min-w-[200px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <ul className="">
                {values.map((field, index) => {
                  const active = field.key === selectedValue.key
                  return (
                      <li
                          onClick={() => {
                            onChange(field)
                            close()
                          }}
                          className={`flex items-center cursor-pointer text-sm lg:text-base font-medium pt-3 pb-3 ${
                              active
                                  ? ""
                                  : "text-neutral-500 hover:text-neutral-100 dark:hover:text-neutral-700 hover:bg-gray-800 dark:hover:bg-gray-100"
                          } `}
                          key={index}
                      >
                        {active ? (
                            <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
                        ) : (
                            <div style={{ width: 20, height: 10}}> </div>
                          )}
                        <span>{getFieldName(field.key)}</span>
                      </li>
                  );
                })}
              </ul>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default FieldSelect;
