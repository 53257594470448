import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useStoreActions, useStoreState } from "store";
import { useTranslation } from "react-i18next";

export interface PageChangePasswordProps {
  className?: string;
}

const validationSchema = Yup.object().shape({
  code: Yup.string().trim().required("Acest câmp este obligatoriu"),
});
const PageChangePassword: FC<PageChangePasswordProps> = ({
  className = "",
}) => {
  const { verifyMail } = useStoreActions((store) => store.auth);
  const { verifyEmail } = useStoreState((store) => store.auth);
  const { t } = useTranslation();
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Verifica email Parola</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("checkEmaiTitle")}
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}
          <Formik
            initialValues={{ code: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              verifyMail({
                code: values.code,
              });
              setSubmitting(false);
            }}
          >
            {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    {t("userFiels.btnContinue", { email: verifyEmail })}
                  </span>
                  <Input
                    type="text"
                    error={errors.code}
                    touched={touched.code}
                    placeholder={t("userFiels.emailCode")}
                    name="code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </label>
                <div className="flex-1 ">
                  <ButtonPrimary
                    type="submit"
                    className="w-full"
                    onClick={handleSubmit}
                  >
                    {t("userFiels.btnContinue")}
                  </ButtonPrimary>
                </div>
              </>
            )}
          </Formik>

          {/* ==== */}
          <p className="my-20 flex items-center text-xl leading-[115%] md:text-xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            <Link to="/signup">
              {t("userFiels.noAccountText")}{" "}
              <span className="underline"> {t("userFiels.hear")}</span>{" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageChangePassword;
