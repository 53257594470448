import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import api from "api";
import { NearbyLocations } from "containers/LocationDetailsPage/types";
import { LOCATIONS } from "models/apiRoutes";
import { useState, useRef, FC } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";

interface Props {
  onClick?: () => void;
  onChange?: (value: NearbyLocations) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
}

const LocationInput: FC<Props> = ({ onChange = () => {}, className = "" }) => {
  const { height } = useWindowSize();
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [searcLocatios, setSearchLocations] = useState<NearbyLocations[] | []>(
    []
  );
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const handleSelectLocation = (item: NearbyLocations) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValue(item.name);
      onChange && onChange(item);
    }, 0);
  };
  const fetchLocation = async (term: string) => {
    const { data: nearbyLocations } = await api.get(
      LOCATIONS.SEARCH_LOCATIONS,
      {
        params: {
          searchTerm: term,
          sportName: "",
          limit: 10,
        },
      }
    );
    setSearchLocations(nearbyLocations);
  };

  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: NearbyLocations[];
  }) => {
    return (
      <div>
        <p className="block font-semibold text-base">{heading}</p>
        <div className="mt-3 ">
          {items.map((item) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-600"
                onClick={() => handleSelectLocation(item)}
                key={item._id}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{item.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {t("nav.searchTitle")}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={t("nav.searchInput")}
            value={value}
            onChange={(e) => {
              const term = e.currentTarget.value;
              fetchLocation(term);
              setValue(term);
            }}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7 ">
          {renderSearchValues({
            heading: t("nav.searcTypeLocation"),
            items: searcLocatios,
          })}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
