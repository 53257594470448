// import axios from "axios";

// let store;
// export const setStore = (injectedStore) => (store = injectedStore);

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API,
//   responseType: "json",
//   maxContentLength: Infinity,
//   maxBodyLength: Infinity,
// });

// export default api;
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { AUTH } from "../models/apiRoutes";

let store: any;
export const setStore = (injectedStore: any) => (store = injectedStore);

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  responseType: "json",
});

let isRefreshing = false;
let failedQueue: {
  resolve: (error: any) => void;
  reject: (error: any) => void;
}[] = [];

const processQueue = (error: null, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Request interceptor for API calls
api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const accessToken = await localStorage.getItem("accessToken");
    config.headers = {
      // "Accept-Language": i18n.locale,
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    return config;
  },
  (error: AxiosError) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: any) => {
    // const message = error?.response?.data?.message;
    const originalReq = error.config;
    const status = error?.response?.status;
    const errorCode = error?.response?.data?.errorCodes;
    if (axios.isCancel(error)) {
      // here you check if this is a cancelled request to drop it silently (without error)
      return new Promise(() => {
        //
      });
    }
    if (status === 401 && errorCode === 9) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalReq.headers.Authorization = `Bearer ${token}`;
            return axios(originalReq);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      isRefreshing = true;
      // Try refresh
      const refreshToken = await localStorage.getItem("refreshToken");
      const { data } = await api.post(AUTH.REFRESH_TOKEN, {
        refreshToken: refreshToken,
      });
      await localStorage.setItem("accessToken", data.accessToken);
      originalReq.headers.Authorization = `Bearer ${data.accessToken}`;

      isRefreshing = false;
      processQueue(null, data.accessToken);

      return axios(originalReq);
    } else if (errorCode === 10) {
      processQueue(error, null);
      // Refresh token error logout
      store.dispatch.auth.logout({ isForce: true });
    } else {
      // Network error
      // Toast.show({
      //   type: "error",
      //   text1: "Something is wrong",
      //   text2: "Please try again later",
      //   position: "bottom",
      // });
    }
    return Promise.reject(error);
  }
);

export default api;
