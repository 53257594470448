import moment from "moment";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ModalReserveMobile from "./ModalReserveMobile";
import {SelectedBooking} from "./types";

interface MobileFooterStickyProps {
  selectedBooking: SelectedBooking,
    selectedDate: moment.Moment | null
}

const MobileFooterSticky = ({selectedDate , selectedBooking}: MobileFooterStickyProps) => {
  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-20">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
            {selectedBooking?.totalPrice} Lei
              {(selectedBooking && selectedBooking?.totalPriceNonDiscounted > selectedBooking?.totalPrice) ? (
                  <s className={"pl-2 text-sm"}>{selectedBooking?.totalPrice} Lei</s>
              ): null}
          </span>
            <p className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                {selectedDate?.format("DD MMM")} {selectedBooking.books.map((book, index) => {
                    return (book.hour < 9) ? `0${book.hour}:00` : `${book.hour}:00 ${index + 1 == selectedBooking?.books.length ? "" : ","} `
                })}
            </p>
        </div>
        <ModalReserveMobile
          selectedBooking={selectedBooking}
          selectedDate={selectedDate}
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              onClick={openModal}
            >
              Rezerva
            </ButtonPrimary>
          )}
        />
      </div>
    </div>
  );
};

export default MobileFooterSticky;
