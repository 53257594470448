import { Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
  LifebuoyIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import { useStoreActions, useStoreState } from "store";

export default function AvatarDropdown() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const solutions = [
    {
      name: t("nav.account"),
      href: "/profile?tab=0",
      icon: UserCircleIcon,
    },
    {
      name: t("nav.favoritePlace"),
      href: "/profile?tab=1",
      icon: HeartIcon,
    },
  ];
  const { isAuthenticated, user } = useStoreState((state) => state.auth);
  const { logout } = useStoreActions((state) => state.auth);

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <Avatar
                userName={isAuthenticated ? user.firstName : ""}
                imgUrl={
                  isAuthenticated
                    ? user.imageUser
                    : "https://jogga.ro/default.jpeg"
                }
                sizeClass="w-8 h-8 sm:w-9 sm:h-9"
              />
              <div className="flex items-center mx-1 justify-center flex-1 ">
                <span className="ml-1">
                  {isAuthenticated ? user.firstName : t("nav.myAccount")}
                </span>
                <ChevronDownIcon
                  className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  {isAuthenticated ? (
                    <>
                      <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                        {solutions.map((item, index) => (
                          <button
                            key={index}
                            onClick={() => {
                              close();
                              navigation(item.href);
                            }}
                            className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                              <item.icon
                                aria-hidden="true"
                                className="w-6 h-6"
                              />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium ">
                                {item.name}
                              </p>
                            </div>
                          </button>
                        ))}
                      </div>
                      <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                      <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                        <button
                          onClick={() => logout({})}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                            <ArrowRightOnRectangleIcon className="w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium ">
                              {t("nav.logout")}
                            </p>
                          </div>
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                      <button
                        onClick={() => {
                          close();
                          navigation("/login");
                        }}
                        className="flex border-1 items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <ArrowRightOnRectangleIcon className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-bold">{t("nav.login")}</p>
                        </div>
                      </button>
                      <button
                        onClick={() => {
                          close();
                          navigation("/signup");
                        }}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="ml-4">
                          <p className="text-sm font-bold underline">
                            {t("nav.signup")}
                          </p>
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
