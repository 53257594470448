import auth, { AuthModel } from "./authModel";

export interface StoreModel {
  auth: AuthModel;
}

const model: StoreModel = {
  auth,
};

export default model;
