import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const Toast = (type = "info", message, options = defaultOptions) => {
  if (type === "info") toast.info(message, options);
  else if (type === "success") toast.success(message, options);
  else if (type === "error") toast.error(message, options);
  else if (type === "warn") toast.warn(message, options);
  else if (type === "default") toast(message, options);
  // else if (type === "notification") toast(message, options);
};

export default Toast;
