import { FC, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import LocationCardH from "components/LocationCardH/LocationCardH";
import GoogleMapReact from "google-map-react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Heading2 from "components/Heading/Heading2";
import { CityPosition, NearbyLocations } from "../LocationDetailsPage/types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface SectionGridHasMapProps {
  heading: string;
  subHeading: string;
  locations: NearbyLocations[];
  city: CityPosition;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({
  heading,
  subHeading,
  locations,
  city,
}) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2 heading={heading} subHeading={subHeading} />
          {/*<div className="mb-8 lg:mb-11">*/}
          {/*  <TabFilters />*/}
          {/*</div>*/}
          <div className="grid grid-cols-1 gap-8">
            {locations.map((location) => (
              <div
                key={location._id}
                onMouseEnter={() => setCurrentHoverID((_) => location._id)}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}
              >
                <Link
                  to={`/${location.seoUrl ? location.seoUrl : location._id}`}
                  className="relative w-full"
                >
                  <LocationCardH location={location} />
                </Link>
              </div>
            ))}
          </div>
          {/*<div className="flex mt-16 justify-center items-center">*/}
          {/*  <Pagination />*/}
          {/*</div>*/}
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>{t("ListingLocationsCityPage.showOnMap")}</span>
          </div>
        )}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            {/* <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
              <Checkbox
                className="text-xs xl:text-sm"
                name="xx"
                label="Search as I move the map"
              />
            </div> */}

            {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}
            <GoogleMapReact
              defaultZoom={12}
              defaultCenter={{
                lat: city.position.coordinates[1],
                lng: city.position.coordinates[0],
              }}
              bootstrapURLKeys={{
                key: "AIzaSyBZzGcpGBWH2XHAjmK4DO3x97ikYVSFWw0",
              }}
              yesIWantToUseGoogleMapApiInternals
            >
              {locations.map((item) => (
                <AnyReactComponent
                  isSelected={currentHoverID === item._id}
                  key={item._id}
                  lat={item.position.coordinates[1]}
                  lng={item.position.coordinates[0]}
                  listing={item}
                />
              ))}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
