import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import { useEffect, useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import api from "api";
import { LOCATION } from "../../api/apiRoutes";
import GameImage1 from "../../images/games/game1.png";
import GameImage2 from "../../images/games/game2.png";
import GameImage3 from "../../images/games/game3.png";
import GameImage4 from "../../images/games/game4.png";
import GameImage5 from "../../images/games/game5.png";
import GameImage6 from "../../images/games/game6.png";
import Group1 from "../../images/groups/group1.png";
import Group2 from "../../images/groups/group3.png";
import Group3 from "../../images/groups/group4.png";
import Group4 from "../../images/groups/group5.png";
import SectionHeroFeaturesApp from "../../components/Features/SectionFeaturesApp";
import FullPageLoader from "../FullPageLoader";
import { useTranslation } from "react-i18next";

const FEATURES_JOGGA = [
  {
    title: "homeFeatures.title1",
    subTitle: "homeFeatures.subTitle1",
    description: "homeFeatures.description1",
  },
  {
    title: "homeFeatures.title2",
    subTitle: "homeFeatures.subTitle2",
    description: "homeFeatures.description2",
  },
  {
    title: "homeFeatures.title3",
    subTitle: "homeFeatures.subTitle3",
    description: "homeFeatures.description3",
  },
];
const FeaturesApp1 = [
  {
    src: GameImage1,
    alt: "homeFeatures1.alt1",
  },
  {
    src: GameImage2,
    alt: "homeFeatures1.alt2",
  },
  {
    src: GameImage3,
    alt: "homeFeatures1.alt2",
  },
];
const FeaturesApp2 = [
  {
    src: GameImage4,
    alt: "homeFeatures2.alt1",
  },
  {
    src: GameImage5,
    alt: "homeFeatures2.alt2",
  },
  {
    src: GameImage6,
    alt: "homeFeatures2.alt3",
  },
];
const FeaturesApp3 = [
  {
    src: Group1,
    alt: "homeFeatures3.alt1",
  },
  {
    src: Group2,
    alt: "homeFeatures3.alt2",
  },
  {
    src: Group3,
    alt: "homeFeatures3.alt3",
  },
  {
    src: Group4,
    alt: "homeFeatures3.alt4",
  },
];
export const sportsJogga = [
  { key: "tennis", name: "sports.tennis" },
  { key: "football", name: "sports.football" },
  { key: "tableTennis", name: "sports.tableTennis" },
];
export const allSports = sportsJogga.map((sport) => sport.key);

export interface LocationsCity {
  id: string;
  href: string;
  name: string;
  taxonomy: string;
  thumbnail: string;
}

function PageHome() {
  const { t } = useTranslation();
  const [locations, setLocations] = useState();
  const [locationsCity, setLocationsCity] = useState<LocationsCity[] | []>([]);
  const [activeTab, setActiveTab] = useState(sportsJogga[0]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(LOCATION.GET_LOCATIONS, {
        params: {
          sportName: activeTab.key,
        },
      });
      setLocations(data.locations);
    };
    fetchData();
  }, [activeTab]);

  useEffect(() => {
    const fetchCity = async () => {
      const { data } = await api.get(LOCATION.GET_LOCATIONS_CITY, {
        params: {
          sportName: activeTab.key,
        },
      });
      const formatCategory = data.map(
        (el: {
          _id: string;
          seoUrl: string;
          cityName: string;
          imageUrl: string;
        }) => ({
          id: el._id,
          href: `/city/${el.seoUrl}`,
          name: el.cityName,
          taxonomy: "category",
          thumbnail: el.imageUrl,
        })
      );
      setLocationsCity(formatCategory);
    };
    fetchCity();
  }, []);
  if (!locationsCity || !locationsCity.length) {
    return <FullPageLoader />;
  }
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {locationsCity && locationsCity.length ? (
        <div
          className="w-screen h-screen pb-10 flex items-center justify-center "
          style={{
            backgroundColor: "#045DE9",
          }}
        >
          <SectionHero locationsCity={locationsCity} className="w-full" />
        </div>
      ) : null}
      <div className="container relative ">
        {/* SECTION 1 */}
        {locationsCity && locationsCity.length ? (
          <div className={"pt-16"}>
            <SectionSliderNewCategories
              categories={locationsCity}
              uniqueClassName="PageHome_s1"
              heading={t("home.selectCityTitle")}
            />
          </div>
        ) : null}
        {/* SECTION2 */}

        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces
            locations={locations}
            tabs={sportsJogga}
            heading={t("home.selectSportClubTitle")}
            subHeading={t("home.selectSportClubSubTitle")}
            tabActive={activeTab}
            setActiveTab={(e: { name: string; key: string }) => setActiveTab(e)}
          />
        </div>
        <SectionOurFeatures
          title={t("homeFeatures.headTitle")}
          features={FEATURES_JOGGA}
        />
        <SectionHeroFeaturesApp
          images={FeaturesApp1}
          heading={t("homeFeatures1.headTitle")}
          btnText=""
          subHeading={
            <>
              <div>{t("homeFeatures1.text1")}</div>
              <div className="mt-3">
                <ul>
                  <li className="list-disc list-inside mb-2">
                    {t("homeFeatures1.text2")}
                  </li>
                  <li className="list-disc list-inside mb-2">
                    {t("homeFeatures1.text3")}
                  </li>
                  <li className="list-disc list-inside">
                    {t("homeFeatures1.text4")}
                  </li>
                </ul>
              </div>
            </>
          }
        />
        <SectionHeroFeaturesApp
          images={FeaturesApp2}
          positionImg={"left"}
          heading={t("homeFeatures2.headTitle")}
          btnText=""
          subHeading={
            <>
              <div>
                <ul>
                  <li className="list-disc list-inside mb-2">
                    {t("homeFeatures2.text1")}
                  </li>
                  <li className="list-disc list-inside mb-2">
                    {t("homeFeatures2.text2")}
                  </li>
                  <li className="list-disc list-inside">
                    {t("homeFeatures2.text3")}
                  </li>
                </ul>
              </div>
            </>
          }
        />
        <SectionHeroFeaturesApp
          images={FeaturesApp3}
          heading={t("homeFeatures3.headTitle")}
          btnText=""
          subHeading={
            <>
              <div>{t("homeFeatures3.text1")}</div>
              <div className="mt-3">{t("homeFeatures3.text2")}</div>
              <div className="mt-3"></div>
            </>
          }
        />

        {/* SECTION */}

        <SectionSubscribe2 />
      </div>
    </div>
  );
}

export default PageHome;
