export const facilities = [
    {
        nameIcon: "human-wheelchair",
        name: "Acces persoane cu dizabilitati",
    },
    {
        nameIcon: "slot-machine",
        name: "Tonomat",
    },
    {
        nameIcon: "shower-head",
        name: "Dusuri",
    },
    {
        nameIcon: "thermometer-lines",
        name: "Incalzire iarna",
    },
    {
        nameIcon: "clipboard-outline",
        name: "Inchiriere echipament",
    },
    {
        nameIcon: "ballot-outline",
        name: "Dulapuri",
    },
    {
        nameIcon: "lightbulb-on-outline",
        name: "Nocturna",
    },
    {
        nameIcon: "parking",
        name: "Parcare gratuita",
    },
    {
        nameIcon: "air-filter",
        name: "Sauna",
    },
    {
        nameIcon: "silverware-fork-knife",
        name: "Restaurant",
    },
    {
        nameIcon: "cart-outline",
        name: "Magazin",
    },
    {
        nameIcon: "toilet",
        name: "Toaleta",
    },
    {
        nameIcon: "dresser-outline",
        name: "Vestiar",
    },
    {
        nameIcon: "wifi",
        name: "Wi-Fi"
    },
];
