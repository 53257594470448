import { FC } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import * as Yup from "yup";
import { Formik } from "formik";
import { useStoreActions } from "store";
import { useTranslation } from "react-i18next";

export interface PageForgotPasswordProps {
  className?: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().email().required("Acest câmp este obligatoriu"),
});

const PageForgotPassword: FC<PageForgotPasswordProps> = ({
  className = "",
}) => {
  const { t } = useTranslation();
  const { forgotPassword } = useStoreActions((action) => action.auth);
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("userFiels.forgotPassword")}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              forgotPassword({
                email: values.email,
              });
              setSubmitting(false);
            }}
          >
            {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    {t("userFiels.forgotPasswordDescription")}
                  </span>
                  <Input
                    type="text"
                    error={errors.email}
                    touched={touched.email}
                    placeholder={t("userFiels.email")}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </label>
                <div className="flex-1 ">
                  <ButtonPrimary
                    type={"button"}
                    className="w-full"
                    onClick={handleSubmit}
                  >
                    {t("userFiels.btnContinue")}
                  </ButtonPrimary>
                </div>
              </>
            )}
          </Formik>
          {/* ==== */}
          <p className="my-20 flex items-center text-xl leading-[115%] md:text-xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            <Link to="/signup">
              {t("userFiels.noAccountText")}{" "}
              <span className="underline"> {t("userFiels.hear")}</span>{" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageForgotPassword;
