import React, { FC } from "react";
import rightImgPng from "images/sports.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import google from "../../images/google.png";
import appstore from "../../images/appstore.png";
import imagePng from "../../images/phone.png";
import { useTranslation } from "react-i18next";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  title: string;
  features: {
    title: string;
    subTitle: string;
    description: string;
  }[];
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  features,
  title,
  type = "type1",
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <div className="flex flex-col lg:flex-row lg:items-center">
          <div>
            <h2 className="font-medium text-5xl md:text-5xl xl:text-8xl ">
              Jogga
            </h2>

            <div className="pt-5 flex flex-row justify-center items-center">
              <a
                className="flex-1 pr-1"
                href="https://play.google.com/store/apps/details?id=com.jogga"
                target="_blank"
                rel="noreferrer"
              >
                <img src={google} alt="hero" />
              </a>
              <a
                className="flex-1 pl-1"
                href="https://apps.apple.com/lv/app/jogga/id1611368607"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appstore} alt="hero" />
              </a>
            </div>
          </div>
          <div className="flex-grow">
            <img src={imagePng} alt="hero" />
          </div>
        </div>
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <h2 className="font-semibold text-4xl mt-5">{title}</h2>

        <ul className="mt-16">
          {features.map((feature) => (
            <li key={feature.title} className="space-y-4">
              {feature.title ? <Badge name={t(feature.title)} /> : null}
              <span className="block text-xl font-semibold">
                {t(feature.subTitle)}
              </span>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {t(feature.description)}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
