import React, { Suspense } from "react";
import MyRouter from "routers/index";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <Suspense fallback="...is loading">
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <BrowserRouter basename={"/"}>
          <MyRouter />
        </BrowserRouter>
        <ToastContainer />
      </div>
    </Suspense>
  );
}

export default App;
