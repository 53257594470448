import { FC, ReactNode } from "react";
import { LocationDataType } from "data/types";
import HeaderFilter from "./HeaderFilter";
import LocationCard from "components/LocationCard/LocationCard";

//
export interface SectionGridFeaturePlacesProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabActive: { name: string; key: string };
  tabs: { name: string; key: string }[];
  locations?: LocationDataType[];
  setActiveTab: Function;
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  gridClass = "",
  heading = "Heading",
  subHeading = "Popular places to stay that Chisfis recommends for you",
  tabActive,
  setActiveTab,
  tabs,
  locations,
}) => {
  const renderCard = (location: LocationDataType) => {
    return <LocationCard key={location._id} data={location} />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={tabActive}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={(item) => setActiveTab(item)}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {locations ? locations.map((location) => renderCard(location)) : null}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading={false}>Show me more</ButtonPrimary>
      </div> */}
    </div>
  );
};

export default SectionGridFeaturePlaces;
