import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import api from "api";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface PageContactProps {
  className?: string;
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const info = [
    {
      title: `🗺 ${t("userFiels.addres")}`,
      desc: "St. Stefan Octavian Iosif, Busteni, Romania",
    },
    {
      title: "💌 EMAIL",
      desc: "office.jogga@gmail.com.",
    },
  ];
  const { register, handleSubmit } = useForm();
  const [isSent, setIsSent] = useState(false);

  const submitContactForm = async (data: any) => {
    await api.post("users/contact", data);
    setIsSent(true);
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Jogga - Contact</title>
        <meta
          name="description"
          content="Contact Jogga afla aici tot ce ai nevoie despre aplicatia noastra. Contacteaza-ne online."
        />
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIAL MEDIA
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              {isSent ? (
                <span className="text-base md:text-3xl text-neutral-500 dark:text-neutral-400">
                  Mulțumim pentru contact!
                </span>
              ) : (
                <form
                  onSubmit={handleSubmit((data) => submitContactForm(data))}
                  className="grid grid-cols-1 gap-6"
                  method="post"
                >
                  <label className="block">
                    <Label>{t("userFiels.firstName")}</Label>

                    <Input {...register("name")} type="text" className="mt-1" />
                  </label>
                  <label className="block">
                    <Label>{t("userFiels.email")}</Label>

                    <Input
                      {...register("email")}
                      type="email"
                      className="mt-1"
                    />
                  </label>
                  <label className="block">
                    <Label>{t("userFiels.phone")}</Label>
                    <Input {...register("phone")} type="tel" className="mt-1" />
                  </label>
                  <label className="block">
                    <Label>{t("userFiels.message")}</Label>
                    <Textarea
                      {...register("message")}
                      className="mt-1"
                      rows={6}
                    />
                  </label>
                  <div></div>
                  <ButtonPrimary type="submit">
                    {t("userFiels.sendMessage")}
                  </ButtonPrimary>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default PageContact;
