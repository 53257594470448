import { FC } from "react";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import NcImage from "../../shared/NcImage/NcImage";
import { useTranslation } from "react-i18next";

export interface LocationCardProps {
  className?: string;
  data?: any;
  data2?: any;
  size?: "default" | "small";
}

const LocationCard: FC<LocationCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const { t } = useTranslation();
  const { _id, name, imageUrl, isBookable, address, seoUrl } = data;

  const renderSliderGallery = () => {
    return (
      <Link to={`/${seoUrl ? seoUrl : _id}`} className="relative w-full">
        <div className={`block aspect-w-4 aspect-h-3`}>
          <NcImage src={imageUrl} />
        </div>
      </Link>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400 bold">
            {name}
          </span>

          <div className="flex items-center space-x-2">
            {isBookable ? (
              <Badge name={t("locationCard.isBookable")} color="green" />
            ) : (
              <Badge name={t("locationCard.noIssBookable")} color="gray" />
            )}
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>
        </div>
        {/* <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div> */}
        {/* <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                Număr de terenuri: {fields?.length}
              </span>
            )}
          </span>
        </div> */}
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default LocationCard;
