import { MyBookings } from "containers/LocationDetailsPage/types";
import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

export interface BookingCardProps {
  className?: string;
  data: MyBookings;
}

const BookingCard: FC<BookingCardProps> = ({ className = "", data }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const renderDetailTop = () => {
    return (
      <div>
        <div className="text-sm text-neutral-500 font-normal mt-0.5">
          {data.location.name}
        </div>
        <div className="flex flex-col md:flex-row ">
          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
            <img src={data.location.imageUrl} className="w-10" alt="" />
          </div>
          <div className="flex my-5 md:my-0">
            <div className="ml-4 space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {t("ProfilePage.booking")}
                </span>
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {moment(data.startDateString).format("MMM dddd")}
                </span>
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {moment(data.startDateString).format("hh:mm")} -{" "}
                  {moment(data.endDateString).format("hh:mm")}
                </span>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {renderDetailTop()}
      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="##" className="absolute inset-0" />
        {/* 
        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span> */}

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-24 lg:w-32 flex-shrink-0 d-flex flex">
            <img src={data.location.imageUrl} className="w-10" alt="" />
            <div className="flex1 pl-2 justify-center  sm:hidden">
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {data.location.name}
              </div>
            </div>
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>{moment(data.startDateString).format("hh:mm")}</span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>{moment(data.endDateString).format("hh:mm")}</span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">
                {moment(data.startDateString).format("MM ddd")}
              </span>
              <span className="mx-2">·</span>
              <span>
                {" "}
                {moment(data.endDateString).diff(
                  moment(data.startDateString),
                  "minutes"
                )}{" "}
                {t("ProfilePage.minutes")}
              </span>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">
              {moment(data.startDateString).format("hh:mm")} -{" "}
              {moment(data.endDateString).format("hh:mm")}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.location.name}
            </div>
          </div>

          {/* TYPE */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">
              {" "}
              {moment(data.startDateString).format("MM ddd")}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {moment(data.endDateString).diff(
                moment(data.startDateString),
                "minutes"
              )}{" "}
              {t("ProfilePage.minutes")}
            </div>
          </div>

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-6000">
                {data.priceTotal} lei
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              {t("ProfilePage.intergralPayment")}
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default BookingCard;
