import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import AvatarDropdown from "./AvatarDropdown";
import LangDropdown from "./LangDropdown";
import { useTranslation } from "react-i18next";
import HeroSearchForm2Mobile from "components/HeroSearchForm2Mobile/HeroSearchForm2Mobile";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { t } = useTranslation();
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <div className="hidden xl:flex items-center mx-1 flex-1 ">
            {/* <SearchDropdown /> */}

            <div className="px-1" />
            <ButtonPrimary
              onClick={() => window.open("https://play.jogga.app/")}
            >
              {t("nav.downloadApp")}
            </ButtonPrimary>
          </div>
          <div className="xl:hidden ">
            <div className="mr-1">
              <ButtonPrimary
                className="w-36 py-2 px-2"
                onClick={() => window.open("https://play.jogga.app/")}
              >
                {t("nav.downloadAppMobile")}
              </ButtonPrimary>
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="flex xl:hidden ml-1">{/* <SwitchDarkMode /> */}</div>
          <div className="hidden xl:flex items-center mx-1 justify-center">
            <HeroSearchForm2Mobile />

            {/* <SearchDropdown /> */}
            <LangDropdown />
            <SwitchDarkMode />
          </div>
          <div className="hidden xl:flex items-center mx-1 justify-center flex-1">
            <AvatarDropdown />
          </div>
        </div>
        <div className="flex xl:hidden items-center ml-1">
          <MenuBar />
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
