import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import { history } from "../utils/history";
import Page404 from "containers/Page404/Page404";
import ListingLocationsCityPage from "containers/ListingLocationsCityPage/ListingLocationsCityPage";
import LocationDetailPage from "containers/LocationDetailsPage/LocationDetailsPage";
import PageContact from "containers/PageContact/PageContact";
import SiteHeader from "containers/SiteHeader";
import Terms from "containers/Terms/Terms";
import PrivacyPolicy from "containers/Terms/PrivacyPolicy";
import PageSmsBooking from "../containers/PageSmsBooking/PageSmsBooking";
import PageGames from "../containers/PageGames/PageGames";
import PageGroups from "../containers/PageGroups/PageGroups";
import PageLogin from "../containers/PageLogin/PageLogin";
import PageSignUp from "../containers/PageSignUp/PageSignUp";
import ProfilePage from "../containers/ProfilePage/ProfilePage";
import AccountPage from "../containers/AccountPage/AccountPage";
import PageForgotPassword from "../containers/PageForgotPassword/PageForgotPassword";
import PageChangePassword from "../containers/PageForgotPassword/PageChangePassword";
import PageVerifyEmail from "../containers/PageVerifyEmail/PageVerifyEmail";
import { useStoreActions, useStoreState } from "store";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/:locationUrl/:id", component: PageSmsBooking },
  { path: "/#", exact: true, component: PageHome },
  { path: "/login", component: PageLogin },
  { path: "/signup", component: PageSignUp },
  { path: "/verify-email", component: PageVerifyEmail },
  { path: "/not-found", component: Page404 },
  { path: "/forgot-password", component: PageForgotPassword },
  { path: "/change-password", component: PageChangePassword },
  { path: "/profile", component: ProfilePage, needAuth: true },
  { path: "/account", component: AccountPage },
  { path: "/terms", component: Terms },
  { path: "/contact", component: PageContact },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/games", component: PageGames },
  { path: "/groups", component: PageGroups },
  { path: "/chat", component: PageGames },
  { path: "/city/:cityName/:sportName", component: ListingLocationsCityPage },
  { path: "/city/:cityName", component: ListingLocationsCityPage },
  {
    path: "/:locationSeoUrl",
    component: LocationDetailPage,
  },
  { path: "/*", component: Page404 },
];
const params: any = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop: string) => {
    return searchParams.get(prop);
  },
});
const MyRoutes = () => {
  history.navigate = useNavigate();
  history.location = useLocation();
  const { isAuthenticated } = useStoreState((state) => state.auth);
  const { getMyProfile } = useStoreActions((state) => state.auth);
  useEffect(() => {
    if (isAuthenticated) {
      getMyProfile();
    }
  }, [isAuthenticated]);
  return (
    <>
      <ScrollToTop />
      {!params?.embeded ? <SiteHeader /> : null}
      <Routes>
        {pages.map(({ component, path, needAuth }) => {
          const Component = component;
          if (isAuthenticated && needAuth) {
            return <Route key={path} element={<Component />} path={path} />;
          } else if (!needAuth) {
            return <Route key={path} element={<Component />} path={path} />;
          }
          return null;
        })}
        <Route element={<Page404 />} />
      </Routes>

      <>
        {/* {WIN_WIDTH < 768 && <FooterNav />} */}
        <Footer />
      </>
    </>
  );
};

export default MyRoutes;
