import { FC, Fragment, useEffect, useRef, useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import MobileFooterSticky from "./MobileFooterSticky";
import api from "../../api";
import { LOCATION } from "../../api/apiRoutes";
import urlParamReplacer from "../../utils/urlParamReplacer";
import { useNavigate, useParams } from "react-router-dom";
import {
  BookingDateSelected,
  LocationBookByHour,
  LocationBookByHourField,
  LocationDetails,
  SelectedBooking,
  SelectedBookingBooks,
} from "./types";
import { facilities } from "./facilities";
import SportSelect from "../../components/HeroSearchForm/SportSelect";
import { Helmet } from "react-helmet";
import CheckOutPage from "./CheckOutPage";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import FieldSelect from "../../components/HeroSearchForm/FieldSelect";
import LikeSaveBtns from "./LikeSaveBtns";
import { useTranslation } from "react-i18next";
import ncNanoId from "../../utils/ncNanoId";

export interface LocationDetailsPageProps {
  className?: string;
}

interface ButtonScheduler {
  isDisabled: boolean;
  book: LocationBookByHour;
  softBlock: boolean;
}

const params: any = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop: string) => {
    return searchParams.get(prop);
  },
});

interface SportsSelected {
  selectedSport: string;
  allSports: string[];
}

const LocationDetailsPage: FC<LocationDetailsPageProps> = ({
  className = "",
}) => {
  const { t } = useTranslation();
  const defaultTabActive = {
    name: t("LocationDetailsPage.eitherOne"),
    key: "1",
  };
  let { locationSeoUrl } = useParams();
  const navigate = useNavigate();
  const selectHourRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [sport, setSport] = useState<SportsSelected>({
    selectedSport: "",
    allSports: [],
  });
  const [tabs, setTabs] = useState<{ name: string; key: string }[]>();
  const [tabActive, setActiveTab] = useState<{ name: string; key: string }>(
    defaultTabActive
  );
  const [locationData, setLocationData] = useState<LocationDetails | null>(
    null
  );
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    moment().startOf("day")
  );
  useEffect(() => {
    fetchData(selectedDate);
  }, [locationSeoUrl]);

  /**
   * Fetches data from the API based on the provided date and sport ID.
   *
   * @param {moment.Moment|null} date - The date for which data is fetched. Null values are not allowed.
   * @param {string} sportId - The ID of the sport. This parameter is optional.
   * @returns {Promise<void>} - A Promise that resolves with no result.
   */
  const fetchData = async (date: moment.Moment | null, sportId?: string) => {
    if (!date) return;
    setSelectedBooking(null);
    const payload: any = {
      startDate: date,
      endDate: date?.clone().add(1, "day"),
    };
    if (sportId) {
      payload.sportName = sportId;
    }
    let data;
    try {
      let { data: dateApi } = await api.get(
        urlParamReplacer(LOCATION.GET_LOCATION_DETAILS, locationSeoUrl),
        {
          params: {
            ...payload,
            startDate: payload.startDate.format("YYYY-MM-DDTHH:mm"),
            endDate: payload.endDate.format("YYYY-MM-DDTHH:mm"),
          },
        }
      );
      data = dateApi;
    } catch (e) {
      navigate("/not-found");
    }

    // Set Sport option
    payload.sportName = data.sportIdFilter;
    const sports = new Set(
      data.fields.map((field: { idSport: string }) => field.idSport)
    );
    // @ts-ignore
    const sportArray: string[] = Array.from(sports.values());
    setSport({
      selectedSport: data.sportIdFilter,
      allSports: sportArray,
    });

    // Set Tabs option with fields
    const fieldsBySport = data.fields.filter(
      (field: { idSport: string }) => field.idSport === payload.sportName
    );
    const tabFields = fieldsBySport.map(
      (filed: {
        indoor: boolean;
        fieldName: string;
        _id: string;
        fieldNameShort?: string;
      }) => {
        return {
          name: `Teren ${
            filed?.fieldNameShort ? filed?.fieldNameShort : filed.fieldName
          } ${filed.indoor ? "(acoperit)" : ""}`,
          key: filed._id,
        };
      }
    );
    tabFields.unshift(defaultTabActive);
    setTabs(tabFields);

    // Get max and min hours Interval
    let minHour = 23;
    let maxHour = 0;

    const dayOfWeek = moment(payload.startDate).day();
    const scheduleDay = data.schedule.find(
      (dayInfo: { dayOfWeek: number }) => dayInfo.dayOfWeek === dayOfWeek
    );

    if (scheduleDay.intervals.length === 0) {
      setLocationData(data);
      return;
    }
    const dayMinHourInterval = scheduleDay.intervals.reduce(
      (prev: { startHour: number }, current: { startHour: number }) => {
        return prev.startHour > current.startHour ? current : prev;
      }
    );

    const dayMaxHourInterval = scheduleDay.intervals.reduce(
      (prev: { endHour: number }, current: { endHour: number }) => {
        return prev.endHour > current.endHour ? prev : current;
      }
    );

    if (dayMinHourInterval.startHour < minHour) {
      minHour = dayMinHourInterval.startHour;
    }
    if (dayMaxHourInterval.endHour > maxHour) {
      maxHour = dayMaxHourInterval.endHour;
    }
    //The number of days I requested and for each field I add the day and hours of the program
    const isToday = !moment(payload.startDate)
      .startOf("day")
      .diff(moment().startOf("day"));
    const currentHour = moment().hour();
    const hourInterval = data.hourInterval;
    const locationBooks: LocationBookByHour[] = [];
    if (hourInterval === 1) {
      minHour = minHour % 1 !== 0 ? minHour - 0.5 : minHour;
      maxHour = minHour % 1 !== 0 ? maxHour : maxHour + 0.5;
    }
    for (
      let hourI = minHour;
      hourI <= maxHour - hourInterval;
      hourI = hourI + hourInterval
    ) {
      const locationBook: LocationBookByHour = {
        hour: hourI,
        isSelected: false,
        softBlock: false,
        disabled: false,
        discountPercentage: 0,
        fields: [],
      };
      locationBooks.push(locationBook);

      if (isToday && hourI <= currentHour) continue;
      const isInInterval = scheduleDay.intervals.find(
        (dayInterval: { startHour: number; endHour: number }) => {
          if (dayInterval.startHour <= hourI && dayInterval.endHour > hourI) {
            return dayInterval;
          }
        }
      );
      if (!isInInterval) {
        continue;
      }
      for (let field of data.fields) {
        //Check sport
        if (field.idSport !== payload.sportName) continue;

        const dayMomentHour = moment(payload.startDate)
          .clone()
          .add(hourI, "hours");
        //Check if has free fields
        let occupiedFields = 0;
        data.locationBooks.forEach(
          (dayBookReserved: {
            books: any;
            day: number;
            month: number;
            year: number;
          }) => {
            dayBookReserved.books.reduce(
              (
                accumulator: string[],
                booking: {
                  bookDate: string;
                  bookDateEnd: string;
                  fieldId: string;
                  hour: number;
                  bookId: string;
                }
              ) => {
                if (
                  booking.fieldId === field._id &&
                  !accumulator.some(
                    (bookId: string) => bookId === booking.bookId
                  ) &&
                  (dayMomentHour.isBetween(
                    moment(booking.bookDate),
                    moment(booking.bookDateEnd),
                    "milliseconds",
                    "[)"
                  ) ||
                    (hourInterval === 1 &&
                      dayMomentHour
                        .clone()
                        .add(0.5, "hour")
                        .isBetween(
                          moment(booking.bookDate),
                          moment(booking.bookDateEnd),
                          "milliseconds",
                          "[]"
                        )))
                ) {
                  accumulator.push(booking.bookId);
                  occupiedFields = occupiedFields + 1;
                }
                return accumulator;
              },
              []
            );
          }
        );

        if (occupiedFields >= field.numberOfFields) continue;
        const discountPercentage =
          data?.deal &&
          data.deal.startHour <= hourI &&
          data.deal.endHour > hourI
            ? data.deal.discountPercentage
            : 0;
        locationBook.discountPercentage = discountPercentage;
        const dayPrice = field.pricesList.find(
          (priceList: { dayOfWeek: number }) =>
            priceList.dayOfWeek === dayOfWeek
        );

        const intervalDayPrice = dayPrice.prices.find(
          (priceInterval: { startHour: number; endHour: number }) =>
            priceInterval.startHour <= hourI && priceInterval.endHour > hourI
        );
        //If it is the last half hour, it cannot be reserved because the minimum reservation is one hour
        const lastHour = maxHour - 0.5;
        // @ts-ignore
        locationBook.fields.push({
          softBlock: hourInterval === 0.5 && lastHour === hourI,
          fieldId: field._id,
          price: Math.round(intervalDayPrice.price * hourInterval * 100) / 100,
          fieldName: field.fieldName,
          hour: hourI,
          discountPercentage,
          advancePaymentAmount:
            Math.round(field.advancePaymentAmount * hourInterval * 100) / 100,
        });
      }

      //Not at first index
      if (minHour < hourI) {
        const previousHour = locationBooks[locationBooks.length - 2];
        //If there are no shared filed, disable the land from the previous hour
        previousHour.fields = previousHour.fields.map((fieldPrev) => {
          if (
            !locationBook.fields.some(
              (fieldHour) => fieldHour.fieldId === fieldPrev.fieldId
            )
          ) {
            fieldPrev.softBlock = true;
          }
          return fieldPrev;
        });

        // If the current hour has no free space and the previous one has at least one free space, it cannot be booked
        // directly because the minimum reservation is one hour
        if (
          hourInterval === 0.5 &&
          !locationBook.fields.length &&
          previousHour.fields.length
        ) {
          if (minHour - 0.5 < hourI) {
            const previousHour2 = locationBooks[locationBooks.length - 3];
            // console.log(previousHour2);
            if (!previousHour2.fields.length) {
              previousHour.disabled = true;
              previousHour.discountPercentage = 0;
              previousHour.fields = [];
            }
          }
          previousHour.softBlock = !previousHour.disabled;
          continue;
        }

        // Check if field exist in previous hour
        // If there is no common fields available in both haf hours, it means that the previous haf hour cannot be reserved
        if (
          hourInterval === 0.5 &&
          !previousHour.fields.some((field) =>
            locationBook.fields.some(
              (field2) => field.fieldId === field2.fieldId
            )
          )
        ) {
          previousHour.disabled = true;
        }

        //If it is the last half hour, it cannot be reserved because the minimum reservation is one hour
        if (
          hourInterval === 0.5 &&
          maxHour - 0.5 === hourI &&
          locationBook.fields.length
        ) {
          locationBook.softBlock = true;
        }
      }
    }
    data.locationBooks = locationBooks;
    setLocationData(data);
  };

  /**
   * Opens a modal with the given index.
   *
   * @param {number} index - The index of the modal to be opened.
   * @returns {void}
   */
  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  }

  const getNameFacility = (facilityId: string) => {
    return facilities.find((facility) => facility.nameIcon === facilityId);
  };
  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">
          {t("LocationDetailsPage.description")}
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        <div className="text-neutral-6000 dark:text-neutral-300">
          {locationData?.description ? (
            <div
              dangerouslySetInnerHTML={{ __html: locationData.description }}
            />
          ) : null}
        </div>
        {locationData?.website ? (
          <div className={"textCenter"}>
            <span className="block mt-2 text-gray-800 dark:text-zinc-50 textCenter">
              Pentru mai multe detalii depre {locationData?.name} intrati pe
              siteul nostru:
            </span>
            <a
              href={"https://" + locationData.website}
              className={"textCenter"}
              style={{ color: "#1e90ff" }}
              target={"_blank"} rel="noreferrer"
            >
              {locationData?.website}
            </a>
          </div>
        ) : null}
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Facilităţi</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {locationData?.name} dispune de următoarele facilități
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {locationData?.facilities.map((item) => {
            const facility = getNameFacility(item.img);
            return (
              <div key={item.name} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl" />
                <span>{facility?.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const changeDay = async (date: moment.Moment | null) => {
    const startDate = moment(date).startOf("day");
    setSelectedDate(startDate);
    setSelectedBooking(null);
    await fetchData(startDate, sport.selectedSport);
  };
  const [selectedBooking, setSelectedBooking] =
    useState<SelectedBooking | null>(null);
  const [paymentType, setPaymentType] = useState<string>("full");

  const setBookingDateSelected = (payload: BookingDateSelected | null) => {
    if (!payload) {
      setSelectedBooking(null);
      return;
    }
    const book = payload.book;
    if (book && locationData) {
      let fieldsInCommon: LocationBookByHourField[] | [] = [];
      const hourInterval = locationData?.hourInterval;
      const maxHour = book.hour + payload?.numberOfHour - hourInterval;
      for (let hourI = book.hour; hourI <= maxHour; hourI += hourInterval) {
        //Find what filed they have in common
        if (hourI === book.hour) {
          fieldsInCommon = book.fields;
          continue;
        }
        const bookHourI = locationData.locationBooks.find(
          (bookHour) => bookHour.hour === hourI
        );
        if (!bookHourI) return;
        fieldsInCommon = fieldsInCommon.filter((field) =>
          bookHourI.fields.some(
            (fieldHour) => fieldHour.fieldId === field.fieldId
          )
        );
      }
      if (!fieldsInCommon.length) return;
      let fieldSelected: LocationBookByHourField = fieldsInCommon[0];
      if (tabActive.key !== "1") {
        // @ts-ignore
        fieldSelected = fieldsInCommon.find(
          (field) => field.fieldId === tabActive.key
        );
      }
      if (!fieldSelected) return;

      const books: SelectedBookingBooks[] | [] = [];
      let totalPrice = 0;
      let totalPriceNonDiscount = 0;
      let totalAdvancePayment = 0;
      for (let hourI = book.hour; hourI <= maxHour; hourI += hourInterval) {
        //Find hourPrice
        const bookHourI = locationData.locationBooks.find(
          (bookHour) => bookHour.hour === hourI
        );
        if (!bookHourI) return;
        const fileData = bookHourI.fields.find(
          (field) => field.fieldId === fieldSelected.fieldId
        );
        if (!fileData) return;
        const price = fieldSelected.discountPercentage
          ? Math.round(
              ((fileData.price -
                fileData.price * fieldSelected.discountPercentage) *
                100) /
                100
            )
          : fileData.price;
        // @ts-ignore
        books.push({
          fieldId: fieldSelected.fieldId,
          fieldName: fieldSelected.fieldName,
          bookDate: selectedDate
            ?.clone()
            .add(hourI, "hour")
            .format("YYYY-MM-DDTHH:mm"),
          nonDiscountedPriceHour: fileData.price,
          advancePayment: fileData.advancePaymentAmount,
          price: price,
          hour: hourI,
        });
        totalPrice = totalPrice + price;
        totalPriceNonDiscount = totalPriceNonDiscount + fileData.price;
        totalAdvancePayment =
          totalAdvancePayment + fileData.advancePaymentAmount;
      }

      setSelectedBooking({
        initialBook: book,
        fieldsInCommon,
        locationId: locationData._id,
        payment: "card",
        totalPrice: totalPrice,
        totalPriceNonDiscounted: totalPriceNonDiscount,
        totalAdvancePayment,
        books,
      });
    }
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div ref={selectHourRef}>
          <h2 className="text-2xl font-semibold">
            {t("LocationDetailsPage.availability")}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t("LocationDetailsPage.availabilityDescription")}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <form className="flex flex-col sm:flex-row">
            <div className="flex-1 border border-zinc-50 rounded-3xl m-1 backgroundJogga">
              <SportSelect
                onChange={async (sportId) => {
                  setSelectedBooking(null);
                  setActiveTab(defaultTabActive);
                  await fetchData(selectedDate, sportId);
                }}
                fieldClassName="p-4"
                selectedValue={sport.selectedSport}
                values={sport.allSports}
              />
            </div>
            <div className="flex-1 border border-zinc-50 rounded-3xl m-1 backgroundJogga">
              <ExperiencesDateSingleInput
                defaultValue={selectedDate}
                anchorDirection={"left"}
                fieldClassName="p-4"
                onChange={changeDay}
                className="h-full"
              />
            </div>

            {tabs ? (
              <div className="flex-1 border border-zinc-50 rounded-3xl m-1 backgroundJogga">
                <FieldSelect
                  onChange={async (item) => {
                    setSelectedBooking(null);
                    setActiveTab(item);
                    await fetchData(selectedDate, sport.selectedSport);
                  }}
                  fieldClassName="p-1"
                  selectedValue={tabActive}
                  values={tabs}
                />
              </div>
            ) : null}
          </form>
        </div>

        <div className="shadow-lg shadow-gray-300 mt-1 rounded-xl p-3">
          <span className="block mt-2 mb-2 xl:text-xl font-semibold text-gray-950 dark:text-zinc-50 textCenter">
            {t("LocationDetailsPage.availabilityHour")}{" "}
            {selectedDate?.format("ddd DD MMM")}
          </span>
          <div className={"flex flex-row justify-center"}>
            <div
              className={"bg-red-500 rounded"}
              style={{ width: 20, height: 20 }}
            />
            <span className={"pr-3 pl-1"}>{t("LocationDetailsPage.busy")}</span>
            <div
              className={"bg-green-500 rounded"}
              style={{ width: 20, height: 20 }}
            />
            <span className={"pr-3 pl-1"}>{t("LocationDetailsPage.free")}</span>
          </div>
          <div className="grid grid-cols-3 sm:grid-cols-4">
            {locationData?.locationBooks.map((book, index) => {
              if (tabActive.key === "1") {
                return renderButtonScheduler({
                  isDisabled:
                    !book.fields.length || book.softBlock || book.disabled,
                  softBlock: book.softBlock,
                  book,
                });
              } else {
                // @ts-ignore
                const filed = book.fields.find(
                  (field) => field.fieldId === tabActive.key
                );
                const isDisabled = !filed;
                return renderButtonScheduler({
                  isDisabled:
                    isDisabled ||
                    !!(
                      filed &&
                      filed?.softBlock &&
                      locationData?.hourInterval === 0.5
                    ),
                  softBlock: !!(
                    filed &&
                    filed?.softBlock &&
                    locationData?.hourInterval === 0.5
                  ),
                  book,
                });
              }
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderButtonScheduler = ({
    isDisabled,
    book,
    softBlock,
  }: ButtonScheduler) => {
    return (
      <button
        key={ncNanoId()}
        style={{
          opacity: isDisabled ? 0.7 : 1,
        }}
        disabled={isDisabled}
        onClick={() => setBookingDateSelected({ book, numberOfHour: 1 })}
        className={
          "p-3 m-1 " +
          (isDisabled && !softBlock
            ? "text-gray-300 dark:text-neutral-700 bg-red-500"
            : book.isSelected
            ? "bg-primary-6000 text-white "
            : "hover:bg-gray-800 hover:text-white bg-green-500 ")
        }
      >
        <div className={"flex align-middle justify-center"}>
          <div
            style={{
              opacity: book.discountPercentage ? 1 : 0,
              backgroundColor: "red",
              fontSize: 14,
              paddingTop: 2,
              paddingRight: 5,
              paddingLeft: 5,
              borderRadius: 5,
              color: "white",
              marginTop: -17,
            }}
          >
            <span>{-book.discountPercentage * 100} %</span>
          </div>
        </div>

        <p className="text-center">
          {" "}
          {book.isSelected ? (
            <i className="las fa-minus-circle" />
          ) : (
            <i className="las fa-plus-circle" />
          )}{" "}
          {moment().startOf("day").add(book.hour, "h").format("HH:mm")}
        </p>
      </button>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          {" "}
          {t("LocationDetailsPage.reviews")} (23)
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>
              {t("LocationDetailsPage.moreReviews")}
            </ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">
            {t("LocationDetailsPage.location")}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {locationData?.address}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          {locationData?.position?.coordinates ? (
            <div className="rounded-xl overflow-hidden">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBZzGcpGBWH2XHAjmK4DO3x97ikYVSFWw0",
                }}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={15}
                defaultCenter={{
                  lat: locationData?.position?.coordinates[1],
                  lng: locationData?.position?.coordinates[0],
                }}
              >
                <LocationMarker
                  lat={locationData?.position?.coordinates[1]}
                  lng={locationData?.position?.coordinates[0]}
                />
              </GoogleMapReact>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          {" "}
          {t("LocationDetailsPage.thingsToKnow")}
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">
            {t("LocationDetailsPage.thingsToKnowTitle1")}
          </h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {t("LocationDetailsPage.thingsToKnowDescription1")}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">
            {t("LocationDetailsPage.thingsToKnowTitle2")}
          </h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {t("LocationDetailsPage.thingsToKnowDescription2")}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      </div>
    );
  };
  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">
            {t("LocationDetailsPage.schedule")}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t("LocationDetailsPage.scheduleDescription")}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {locationData?.schedule.map((day, index) => (
              <div
                key={day._id}
                className={`p-4 ${
                  index % 2 ? "bg-neutral-100  dark:bg-neutral-800" : ""
                } flex justify-between items-center space-x-4 rounded-lg`}
              >
                <span>
                  {moment()
                    .startOf("isoWeek")
                    .add(day.dayOfWeek - 1, "day")
                    .format("dddd")}
                </span>
                {day.intervals.length ? (
                  <div>
                    {day.intervals.map((interval) => (
                      <div key={interval._id}>
                        <p>
                          {moment()
                            .startOf("day")
                            .add(interval.startHour, "h")
                            .format("HH:mm")}
                          {" - "}
                          {moment()
                            .startOf("day")
                            .add(interval.endHour, "h")
                            .format("HH:mm")}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-rose-600">
                    {t("LocationDetailsPage.unavailable")}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {t("LocationDetailsPage.location")}
          </span>
          {/*<StartRating />*/}
        </div>
        <span className="block text-neutral-500 dark:text-neutral-400">
          {locationData?.address}
        </span>
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          {locationData?.position?.coordinates ? (
            <div className="rounded-xl overflow-hidden">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBZzGcpGBWH2XHAjmK4DO3x97ikYVSFWw0",
                }}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={13}
                defaultCenter={{
                  lat: locationData?.position?.coordinates[1],
                  lng: locationData?.position?.coordinates[0],
                }}
              >
                <LocationMarker
                  lat={locationData?.position?.coordinates[1]}
                  lng={locationData?.position?.coordinates[0]}
                />
              </GoogleMapReact>
            </div>
          ) : null}

          {/* FORM */}
          {/*<div>*/}
          {/*  {selectedBooking ? (*/}
          {/*      <div className="flex flex-col space-y-4">*/}
          {/*        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>*/}
          {/*        <div className="flex justify-between font-semibold">*/}
          {/*          <div>*/}
          {/*            <p>Rezervare {selectedDate?.format("DD MMM")} {selectedBooking.books.length } {selectedBooking.books.length > 1 ? "ore" : "ora"}</p>*/}
          {/*            <p style={{*/}
          {/*              maxWidth: 300*/}
          {/*            }}> {selectedBooking.books.map((book, index) =>(*/}
          {/*                <p>{(book.hour < 10) ? `0${book.hour}:00` : ` ${book.hour}:00`} - {(book.hour + 1 < 10) ? `0${book.hour + 1}:00` : `${book.hour + 1}:00`}</p>*/}
          {/*                )*/}

          {/*            )}</p>*/}
          {/*          </div>*/}

          {/*          <div>*/}
          {/*            {(selectedBooking && selectedBooking?.totalPriceNonDiscounted > selectedBooking?.totalPrice) ? (*/}
          {/*                <s className={"pr-2 text-sm"}>{selectedBooking?.totalPrice} Lei</s>*/}
          {/*            ): null}*/}
          {/*            <span>{selectedBooking?.totalPrice} Lei</span>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*  ): (*/}
          {/*      <ButtonPrimary onClick={() => {*/}
          {/*        if(selectHourRef?.current){*/}
          {/*          selectHourRef?.current.scrollIntoView({ behavior: 'smooth', block: 'start' })*/}
          {/*        }*/}

          {/*      } }>Selecteaza ora rezervarii</ButtonPrimary>*/}
          {/*  )}*/}
          {/*  <div>*/}
          {/*</div>*/}
        </div>

        {/* SUM */}
        {selectedBooking ? (
          <div className="flex flex-col space-y-4">
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex justify-between font-semibold">
              <span>Total</span>
              <div>
                {selectedBooking &&
                selectedBooking?.totalPriceNonDiscounted >
                  selectedBooking?.totalPrice ? (
                  <s className={"pr-2 text-sm"}>
                    {selectedBooking?.totalPrice} Lei
                  </s>
                ) : null}
                <span>{selectedBooking?.totalPrice} Lei</span>
              </div>
            </div>
          </div>
        ) : null}

        <Transition appear show={!!selectedBooking} as={Fragment}>
          <Dialog
            as="div"
            className="HeroSearchFormMobile__Dialog relative z-50"
            onClose={() => setBookingDateSelected(null)}
          >
            <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
              <div className="flex h-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out transition-transform"
                  enterFrom="opacity-0 translate-y-52"
                  enterTo="opacity-100 translate-y-0"
                  leave="ease-in transition-transform"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-52"
                >
                  <Dialog.Panel className="relative h-full flex-1 flex flex-col justify-between overflow-auto">
                    <>
                      <div className="absolute left-4 top-2">
                        <button
                          className="focus:outline-none focus:ring-0"
                          onClick={() => setBookingDateSelected(null)}
                        >
                          <XMarkIcon className="w-10 h-10 text-black bold dark:text-white" />
                        </button>
                      </div>

                      <div className="flex-1 pt-12 py-1 flex flex-col ">
                        <div className="flex-1 bg-white dark:bg-neutral-900">
                          {selectedBooking ? (
                            <CheckOutPage
                              selectedBooking={selectedBooking}
                              selectedDate={selectedDate}
                              locationData={locationData}
                              setBookingDateSelected={setBookingDateSelected}
                              paymentType={paymentType}
                              setPaymentType={setPaymentType}
                            />
                          ) : null}
                        </div>
                      </div>
                    </>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        {/* SUBMIT */}
        {/*{selectedBooking ? (*/}
        {/*        <ModalReserveMobile*/}
        {/*            selectedBooking={selectedBooking}*/}
        {/*            selectedDate={selectedDate}*/}
        {/*            renderChildren={({ openModal }) => (*/}
        {/*                <ButtonPrimary*/}
        {/*                    sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"*/}
        {/*                    onClick={openModal}*/}
        {/*                >*/}
        {/*                  Rezerva*/}
        {/*                </ButtonPrimary>*/}
        {/*            )}*/}
        {/*        />*/}
        {/*): null}*/}
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ListingExperiencesDetailPage ${className}`}
      data-nc-id="ListingExperiencesDetailPage"
    >
      <Helmet>
        <title>
          {locationData?.seoMetadataTitle
            ? locationData?.seoMetadataTitle
            : `${locationData?.name} | Rezerva terenul si hai la sport`}
        </title>
        <meta
          name="description"
          content={
            locationData?.seoMetadataBody
              ? locationData?.seoMetadataBody
              : "Vrei sa faci sport in cele mai bune conditi la cel mai bun pret. Rezerva si ne vedem la teren."
          }
        />
      </Helmet>
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-12 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName=" justify-center inset-0"
                className="object-cover w-full h-60 rounded-md sm:rounded-xl justify-center"
                src={locationData?.imageUrl}
              />
            </div>
            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                {t("LocationDetailsPage.seePhoto")}
              </span>
            </div>
            {locationData && sport.selectedSport ? (
              <div className="absolute md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 rounded-xl text-neutral-500 cursor-pointer z-10">
                <LikeSaveBtns
                  isFavorite={locationData?.isFavorite}
                  locationId={locationData?._id}
                  sportId={sport.selectedSport}
                  reFetch={() => fetchData(selectedDate, sport.selectedSport)}
                />
              </div>
            ) : null}
          </div>
        </header>
        {/* MODAL PHOTOS */}
        {locationData?.imageUrl ? (
          <ModalPhotos
            imgs={[locationData?.imageUrl]}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-ListingExperiencesDetailPage__modalPhotos"
          />
        ) : null}
      </>
      <h1 className="text-3xl font-semibold text-center mt-6">
        {locationData?.name}
      </h1>
      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          {locationData?.isBookable ? renderSectionCheckIndate() : null}

          {renderSection2()}
          {renderSection3()}
          {renderSection4()}
          {/*{renderSection6()}*/}
          {renderSection7()}
          {renderSection8()}
        </div>

        {/* SIDEBAR */}
        {locationData?.isBookable ? (
          <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
            <div className="sticky top-28">{renderSidebar()}</div>
          </div>
        ) : null}
      </main>

      {/* STICKY FOOTER MOBILE */}
      {selectedBooking ? (
        <MobileFooterSticky
          selectedBooking={selectedBooking}
          selectedDate={selectedDate}
        />
      ) : null}

      {/* OTHER SECTION */}
      {!params?.embeded ? (
        <div className="container py-24 lg:py-32">
          {/* SECTION */}
          <SectionSubscribe2 className="pt-24 lg:pt-32" />
        </div>
      ) : null}
    </div>
  );
};

export default LocationDetailsPage;
