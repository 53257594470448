import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Terms = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl pb-5">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Termeni și condiții
          </h1>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p>
          Prin descărcarea sau utilizarea aplicației, acești termeni se vor
          aplica automat în cazul dumneavoastră. Ar trebui să vă asigurați, prin
          urmare, că îi citiți cu atenție înainte de a utiliza aplicația. Nu
          aveți voie să copiați sau să modificați aplicația, nicio parte a
          aplicației sau mărcile noastre comerciale în niciun fel. Aplicația în
          sine, precum și toate mărcile comerciale, drepturile de autor,
          drepturile de bază de date și alte drepturi de proprietate
          intelectuală aferente acesteia aparțin WEVIEW TECH SRL.
        </p>
        <h3>
          <strong>Informații generale</strong>
        </h3>
        <p>
          Siteul www.jogga.ro (denumit în continuare site-ul web) este deținut
          de: WEVIEW TECH SRL S.R.L., St. Stefan Octavian Iosif, Busteni,
          Romania. Acest document (precum și toate celelalte documente
          menționate aici) reglementează condițiile de utilizarea ale acestui
          site web ( www.jogga.ro ) și achiziționarea sau achiziția de produse
          și / sau servicii din acesta (în avans, in condițiile siteului). În
          sensul prezentelor condiții, se înțelege că activitatea pe care JOGGA
          o dezvoltă prin intermediul site-ului include: Rezervare de terenuri
          pentru sporturi individuale (tenis, paddle, etc), terenuri pentru
          sporturi de echipa (fotbal, bachet, etc). Pe lângă citirea acestor
          Condiții, înainte de accesarea, navigarea și / sau utilizarea acestui
          site, utilizatorul trebuie să fi citit inclusiv politica privind
          cookie-urile și politica de confidențialitate.
        </p>
        <h3>
          <strong>Protecția datelor JOGGA</strong>
        </h3>
        <p>
          Utilizând acest site web și / sau solicitând achiziția unui produs și
          / sau serviciu prin intermediul acestuia, Utilizatorul acceptă să fie
          obligat de acești Termeni și de toate cele de mai sus, deci, dacă nu
          sunteți de acord în totalitate, nu trebuie sa utilizați acest site
          web. De asemenea, utilizatorul este informat că aceste Condiții ar
          putea fi modificate. Utilizatorul este responsabil pentru consultarea
          acestora de fiecare dată când accesează, navighează și / sau
          utilizează site-ul Web, deoarece se vor aplica cele care sunt în
          vigoare în momentul în care se solicită achiziția de produse și / sau
          servicii. Pentru toate întrebările pe care Utilizatorul le poate avea
          în legătură cu Condițiile, puteți contacta proprietarul folosind
          informațiile de contact furnizate mai sus sau, dacă este cazul,
          folosind formularul de contact.
        </p>
        <h3>
          <strong>Utilizatorul</strong>
        </h3>
        <p>
          Accesul, navigarea și utilizarea site-ului Web, conferă condiția
          utilizatorului (denumită în continuare, indistinct, individual ca
          Utilizator sau în comun ca Utilizatori), pentru care sunt acceptate,
          de când începe navigarea site-ului, toate condițiile prezentate aici ,
          precum și modificările ulterioare ale acestora, fără a aduce atingere
          aplicării reglementărilor legale corespunzătoare de conformitate
          obligatorie, după caz. Utilizatorul își asumă responsabilitatea pentru
          utilizarea corectă a site-ului Web. Această responsabilitate se va
          extinde la:
          <ol>
            <li>
              Utilizați acest site web doar pentru a cauta și a face cumpărături
              sau achiziții valabile legal.
            </li>
            <li>
              Nu faceți nicio achiziție falsă sau frauduloasă. Dacă s-ar putea
              considera în mod rezonabil că s-a făcut o achiziție de acest tip,
              aceasta ar putea fi anulată și autoritățile relevante informate.
            </li>
            <li>
              Furnizați date de contact veridice și legale, de exemplu, adresa
              de e-mail, adresa poștală și / sau alte informații (a se vedea
              avizul legal și condițiile generale de utilizare).
            </li>
            <li>
              Site-ul web se adresează în principal utilizatorilor cu domiciliul
              în Romania. JOGGA nu se asigură că Website-ul respectă legile
              altor țări, total sau parțial. JOGGA declina orice
              responsabilitate care poate apărea din acest acces și nu asigură
              livrări sau prestări de servicii în afara Romaniei
            </li>
            <li>
              Utilizatorul declară că are vârsta de peste 18 ani și are
              capacitatea legală de a încheia contracte prin intermediul acestui
              site Web.
            </li>
            <li>
              Utilizatorul poate oficializa, la opțiunea sa, cu JOGGA contractul
              de vânzare a produselor și / sau serviciilor dorite în oricare
              dintre limbile în care aceste Condiții sunt disponibile pe acest
              site Web.
            </li>
          </ol>
        </p>
        <h3>
          <strong>Cumpărare sau procesul de achiziție</strong>
        </h3>
        <p>
          Utilizatorii înregistrați în mod corespunzător pot cumpăra pe site-ul
          Web prin mijloacele și formularele stabilite. Trebuie să urmați
          procedura de achiziție și / sau achiziție online a site-ului
          www.jogga.ro, în timpul căreia mai multe produse și / sau servicii pot
          fi selectate și adăugate în coș. De asemenea, Utilizatorul trebuie să
          completeze și / sau să verifice informațiile solicitate în fiecare
          etapă. În continuare, Utilizatorul va primi un e-mail care confirmă
          faptul că JOGGA a primit comanda sau cererea de cumpărare și / sau
          furnizare a serviciului, adică confirmarea comenzii. Și, în cazul
          dvs., veți fi informat, de asemenea, prin e-mail în momentul
          trimiterii achiziției. În cazul dvs., aceste informații pot fi de
          asemenea puse la dispoziția utilizatorului prin spațiul personal de
          conectare la site-ul Web. După finalizarea procedurii de cumpărare,
          Utilizatorul consimte că Website-ul generează o comanda catre
          furnizorul final care va presta serviciul pentru Utilizator.
          Utilizatorul poate, dacă dorește, să obțină o copie a facturii sale
          solicitând-o de la furnizorul de servicii unde a achizitionat
          serviciul. Utilizatorul recunoaște că este la curent, la momentul
          achiziției, de anumite condiții particulare de vânzare care privesc
          produsul și / sau serviciul în cauză și care sunt afișate lângă
          prezentare sau, după caz, imagine a acestuia pe pagina dvs. a
          site-ului Web, indicând, de exemplu, dar nu exhaustiv și ținând cont
          de fiecare caz: numele, prețul, componentele, greutatea, cantitatea,
          culoarea, detaliile produselor sau caracteristicile, modul în care
          acestea vor fi efectuate și / sau costul beneficilor; și recunoaște că
          executarea ordinului de achiziție concretizează acceptarea completă și
          în întregime a condițiilor particulare de vânzare aplicabile fiecărui
          caz.{" "}
          <b>
            Utilizatorul știe că JOGGA îi arată pentru interesul său o serie de
            servicii pe care proprietarul nu le acordă Utilizatorilor direct,
            dar că vor fi angajate și furnizate de către un terț, altul decât
            JOGGA.
          </b>{" "}
          Comunicările, comenzile de cumpărare și plățile care au loc în timpul
          tranzacțiilor efectuate pe site-ul Web pot fi arhivate și păstrate în
          evidențele computerizate ale JOGGA cu scopul de a constitui un mijloc
          de dovedire a tranzacțiilor, în orice caz, respectând condițiile
          rezonabile de securitate, legile și reglementările în vigoare care în
          acest sens sunt aplicabile, în special în conformitate cu GDPR și
          drepturile care ajută Utilizatorii în conformitate cu politica de
          confidențialitate a acestui site Web (Notificare legală și Condiții
          generale de utilizare).
        </p>
        <h3>
          <strong>Disponibilitate</strong>
        </h3>
        <p>
          Toate comenzile de cumpărare primite de către JOGGA prin intermediul
          site-ului Web sunt supuse disponibilității produselor și / sau că
          nicio circumstanță sau cauză de forță majoră nu afectează furnizarea
          de servicii. Dacă există dificultăți în furnizarea de produse sau nu
          rămân stocuri, JOGGA restituie credit in contul utilizatorului pentru
          alte achizitii sau ramburseaza suma care a fost plătită pentru acel
          serviciu / produs. Acest lucru se va aplica în mod egal în cazurile în
          care prestarea unui serviciu devine de nerealizat.
        </p>
        <h3>
          <strong>Preturi și plată</strong>
        </h3>
        <p>
          Prețurile afișate pe site-ul web sunt cele finale, în lei și includ
          TVA (in cazul in care respectivul club este platitor de TVA) Cu toate
          acestea, dacă nu se indică altfel, prețurile articolelor oferite
          exclud costurile de transport, care se vor adăuga la suma totală. În
          niciun caz, site-ul Web nu va adăuga costuri suplimentare la prețul
          unui produs sau serviciu automat, ci doar cele pe care Utilizatorul
          le-a selectat și ales în mod voluntar și liber. Prețurile se pot
          modifica oricând, dar eventualele modificări nu vor afecta comenzile
          sau cumpărăturile pentru care Utilizatorul a primit deja o confirmare
          a comenzii. Mijloacele de plată acceptate vor fi: Card de credit sau
          debit. JOGGA folosește toate mijloacele pentru a garanta
          confidențialitatea și securitatea datelor de plată transmise de
          Utilizator în timpul tranzacțiilor prin intermediul site-ului Web. Ca
          atare, site-ul web utilizează un sistem de plată securizat furnizat de
          NETOPIA Payments SRL, SSL (Secure Socket Layer). Cardurile de
          credit/debit vor fi supuse autorizațiilor de către banca emitenta, iar
          în cazul în care entitatea respectivă nu autorizează plata, JOGGA nu
          va fi responsabil pentru nicio întârziere sau nerespectare a
          comenzilor asociate tranzactiei și nu va putea încheia niciun contract
          cu Utilizatorul. Odată ce JOGGA primește comanda de cumpărare de la
          Utilizator prin intermediul site-ului Web, se va face o cerere de
          plata catre furnizorul de servicii de plata cu cardul (NETOPIA
          Payments SRL) unde Utilizatorul face plata efectiva a comenzii. Făcând
          clic pe „plată”, Utilizatorul confirmă că metoda de plată utilizată
          este a acestuia sau că, după caz, este deținătorul legitim al cardului
          cadou sau al cardului de credit.
        </p>

        <h3>
          <strong>Promoții</strong>
        </h3>
        <p>
          WEVIEW TECH SRL S.R.L., St. Stefan Octavian Iosif, Busteni, Romania va
          desfășura diferite promoții destinate tuturor utilizatorilor
          potențiali ai aplicatiei JOGGA, cu scopul de a promova serviciile
          oferite, descărcări ale aplicației JOGGA în Romania de către
          utilizatori noi (în continuare „Aplicația”).
          <ol>
            <li>
              Domeniul teritorial și aplicarea promoției: aceste promoții vor fi
              realizate în Romania, exclusiv pentru utilizatorii aplicației care
              se află pe teritoriul Romaniei.
            </li>
            <li>
              Domeniu temporal: Perioada de participare la fiecare promoție va
              începe la data lansării și se va încheia la data indicată în
              comunicarea fiecăreia dintre promoțiile publicate de JOGGA.
            </li>
            <li>
              Natura gratuită a promoției: Promoțiile vor fi realizate sub
              modalitatea de „gratuit”, adică participarea la aceste Promoții nu
              va implica nicio cheltuială pentru participant.
            </li>
            <li>
              Comunicarea promoției: Promoțiile vor fi comunicate în perioada în
              care sunt active.
            </li>
            <li>
              Persoane legitime și mecanisme ale promoției: condițiile de
              participare la aceste promoții vor fi determinate de
              particularitatea acestora. În acest fel, fiecare Promoție va avea
              condiții diferite pe care utilizatorul trebuie să le respecte în
              fiecare caz. Aceste condiții, pe lângă dinamica de participare,
              vor fi specificate în comunicarea emisă de JOGGA pentru fiecare
              dintre promoțiile lansate.
            </li>
            <li>
              Rezervări și limitări: JOGGA își rezervă dreptul de a respinge
              înregistrarea acelor participanți ale căror date nu sunt complete
              sau codurile lor nu îndeplinesc cerințele de participare la
              Promoții. JOGGA își rezervă dreptul de a face modificări care să
              conducă la bun sfârșit Promoțiile, atunci când există motive de
              cauză sau de forță majoră care împiedică finalizarea acestuia în
              modul descris în aceste reguli.
            </li>
            <li>
              Acceptare: Participarea la Promoții presupune, de la sine,
              acceptarea bazelor prezente. Lipsa acestei acceptări din partea
              participantului va duce la excluderea participării lor la
              Promoții.
            </li>
            <li>
              Prelucrarea datelor cu caracter personal: În conformitate cu
              prevederile Legii 15/1999, a Legii privind protecția datelor cu
              caracter personal, vă informăm că datele personale pe care ni le
              furnizați vor fi salvate in locatii cu acces securizat. În
              conformitate cu prevederile GDPR, la momentul colectării datelor,
              utilizatorilor va fi solicitat consimțământul expres, astfel încât
              JOGGA să poată folosi datele lor pentru a trimite informații și
              publicitate prin e-mail sau mijloace echivalente, cum ar fi
              SMS-urile pe telefoanele mobile. Aceste date vor fi păstrate în
              cea mai strictă confidențialitate, în conformitate cu legislația
              în vigoare. Participanții la aceste promoții și utilizatorii
              înregistrați pot exercita, în legătură cu datele lor personale,
              drepturile de acces, rectificare, anulare și revocare a
              consimțământului pentru transferul acestora, printr-un e-mail
              adresat: office.jogga@gmail.com
            </li>
          </ol>
        </p>

        <h3>
          <strong>Mediu tehnic pentru corectarea erorii</strong>
        </h3>
        <p>
          Utilizatorul este informat că dacă detectează o eroare la introducerea
          datelor necesare procesării cererii sale de cumpărare pe site-ul Web,
          el le poate modifica contactând JOGGA prin spațiile de contact
          activate, pe site-ul Web și, după caz, prin contactarea serviciul
          pentru clienți și / sau folosind informațiile de contact prevăzute în
          prima clauză (Informații generale). Utilizatorul, înainte de a face
          clic pe „plată”, are acces la coșul de cumparaturi în care își notează
          cererile de cumpărare și poate face modificări. De asemenea,
          utilizatorul este trimis să consulte Condițiile generale de utilizare
          pentru a face achizitia in cunoscand toate implicatiile legale.
        </p>
        <h3>
          <strong>Anulare</strong>
        </h3>
        <p>
          În cazurile în care Utilizatorul achiziționează produse de pe sau prin
          intermediul site-ului Web al proprietarului, acesta este asistat de o
          serie de drepturi, astfel cum sunt enumerate și descrise mai jos:
          <ol>
            <li>Dreptul de anulare</li>
            <li>
              Pentru a exercita acest drept de retragere, Utilizatorul trebuie
              să notifice JOGGA decizia saa.
            </li>
            <li>
              Utilizatorul, indiferent de mijloacele pe care le alege pentru
              a-și comunica decizia, trebuie să exprime clar și fără echivoc că
              este intenția sa să renunțe la contractul de cumpărare. Pentru a
              respecta perioada de retragere, este suficient ca comunicarea care
              exprimă fără echivoc decizia de abandonare să fie trimisă înainte
              de expirarea termenului corespunzător.
            </li>
            <li>
              În cazul retragerii, JOGGA va rambursa Utilizatorul pentru toate
              plățile primite, fără nicio întârziere nejustificată și, în orice
              caz, în cel mult 14 zile calendaristice de la data la care JOGGA
              este informat despre decizia de anulare a Utilizatorului. .JOGGA
              va rambursa Utilizatorul in puncte credit echivalente sau folosind
              aceeași metodă de plată pe care a folosit-o pentru a efectua
              tranzacția inițială de cumpărare. Această restituire nu va genera
              niciun cost suplimentar pentru Utilizator.
            </li>
            <li>
              În același sens, furnizarea unui serviciu pe care Utilizatorul
              l-ar putea contracta pe acest site Web este reglementată, deoarece
              Dreptul de retragere al Utilizatorului nu poate fi folosit atunci
              când furnizarea serviciului a fost executată în totalitate sau
              atunci când început. Acordul expres al consumatorului și
              utilizatorului și confirmarea conditiilor de achizitie din partea
              sa arata că este conștient de faptul că, după ce contractul a fost
              executat integral de către JOGGA, acesta și-a pierdut dreptul de
              retragere.
            </li>
          </ol>
        </p>
        <h3>
          <strong>Declarația de responsabilitate</strong>
        </h3>
        <p>
          Dacă legea nu prevede altfel, JOGGA nu va accepta nicio răspundere
          pentru următoarele pierderi, indiferent de originea lor: orice
          pierderi care nu erau imputabile niciunei încălcări din partea ta,
          pierderi de afaceri (inclusiv profituri pierdute, venituri, contracte,
          economii anticipate, date, pierderea fondului comercial sau cheltuieli
          inutile efectuate) sau orice altă pierdere indirectă care nu era în
          mod rezonabil previzibilă de către ambele părți la momentul
          formalizării contractului de vânzare a produselor între ambele părți.
          De asemenea, JOGGA își limitează responsabilitatea în următoarele
          cazuri: JOGGA aplică toate măsurile referitoare la furnizarea unei
          afișări fidele a produsului pe site-ul Web, cu toate acestea, nu este
          responsabil pentru diferențele minime sau inexactitățile care pot
          exista din cauza lipsei de rezoluție a ecranului, sau a problemelor
          browserului utilizate sau de alții această natură. JOGGA va acționa cu
          cea mai mare sârguință pentru a pune la dispoziția companiei
          însărcinată cu transportul produsului, obiectele comenzii de
          achiziție. Cu toate acestea, nu este responsabil pentru daunele
          cauzate de o functionare necorespunzatoare a transportului, în special
          din motive precum greve, rețineri rutiere și, în general, orice alte
          elemente specifice sectorului, care au ca rezultat întârzieri,
          pierderi sau furtul produsului. Eșecuri tehnice care din cauze
          fortuite sau de altă natură împiedică o funcționare normală a
          serviciului prin internet. Lipsa disponibilității site-ului Web pentru
          întreținere sau din alte motive, ceea ce împiedică disponibilitatea
          serviciului. JOGGA pune toate mijloacele la dispoziție pentru a
          efectua procesul de cumpărare, plată și livrare / livrare a
          produselor, cu toate acestea este scutit de răspundere pentru cauze
          care nu îi sunt imputabile, eveniment fortuit sau forță majoră. JOGGA
          nu va fi responsabil pentru utilizarea necorespunzătoare și / sau
          uzura produselor care au fost utilizate de Utilizator. În același
          timp, JOGGA nu va fi responsabil pentru o returnare eronată făcută de
          Utilizator. Este responsabilitatea Utilizatorului să returneze
          produsul corect. În general, JOGGA nu va fi responsabil pentru nicio
          încălcare sau întârziere la îndeplinirea oricăreia dintre obligațiile
          asumate, atunci când aceasta se datorează evenimentelor care nu se
          află sub controlul nostru rezonabil, adică a faptului că acestea sunt
          datorate forței majore și acest lucru poate include, fără a se limita
          la: Greve, blocaje sau alte acțiuni industriale, revoltă, invazie,
          amenințare sau atac terorist, război (declarat sau nu) sau amenințare
          sau pregătiri de război. Incendiu, explozie, furtună, inundații,
          cutremur, subsidență, epidemie sau orice alt dezastru natural.
          Imposibilitatea utilizării trenurilor, bărcilor, avioanelor,
          transportului cu motor sau a altor mijloace de transport, publice sau
          private. Incapacitatea de a utiliza sisteme publice sau private de
          telecomunicații. Acte, decrete, legislație, reglementări sau
          restricții ale oricărui guvern sau autoritate publică. În acest fel,
          obligațiile vor fi suspendate în perioada în care cauza de forță
          majoră continuă, iar JOGGA va avea o prelungire în termen pentru a le
          îndeplini pentru o perioadă de timp egală cu durata evenimentului de
          forță majoră. JOGGA va folosi toate mijloacele rezonabile pentru a
          găsi o soluție care să ne permită să ne îndeplinim obligațiile, în
          ciuda cauzei forței majore.
        </p>
        <h3>
          <strong>Comunicări și notificări</strong>
        </h3>
        <p>
          Utilizând acest site Web, Utilizatorul acceptă faptul că majoritatea
          comunicărilor cu JOGGA sunt electronice (e-mail sau anunțuri publicate
          pe site-ul Web). În scopuri contractuale, Utilizatorul consimte să
          utilizeze acest mijloc electronic de comunicare și recunoaște că toate
          contractele, notificările, informațiile și alte comunicări pe care
          JOGGA le trimite electronic respectă cerințele legale de a fi în
          scris. Această condiție nu va afecta drepturile utilizatorului
          recunoscute de lege. Utilizatorul poate trimite notificări și / sau
          comunica cu JOGGA prin datele de contact prevăzute în aceste condiții
          și, după caz, prin spațiile de contact ale site-ului. De asemenea, cu
          excepția cazului în care se prevede altfel, JOGGA poate contacta și /
          sau notifica Utilizatorul prin e-mailul său sau la adresa poștală
          furnizată.
        </p>
        <h3>
          <strong>Nulitatea</strong>
        </h3>
        <p>
          Dacă oricare dintre aceste condiții a fost declarată nulă printr-o
          decizie finală emisă de o autoritate competentă, restul clauzelor vor
          rămâne în vigoare, fără a fi afectate de declarația de nulitate
          menționată.
        </p>
        <h3>
          <strong>Protecția datelor</strong>
        </h3>
        <p>
          Informațiile sau datele personale pe care Utilizatorul le furnizează
          JOGGA în cadrul unei tranzacții pe site-ul Web, vor fi tratate în
          conformitate cu prevederile politicilor de protecție a datelor
          (Condiții generale de utilizare). La accesarea, navigarea și / sau
          utilizarea site-ului Web, Utilizatorul consimte la procesarea
          informațiilor și datelor menționate și declară că toate informațiile
          sau datele furnizate sunt adevărate. Acest document se completează cu{" "}
          <Link to={"/privacy-policy"}>
            <b>
              <u> Politica de Confidențialitate</u>
            </b>
          </Link>
        </p>
        <h3>
          <strong>Legislare aplicabilă și jurisdicție</strong>
        </h3>
        <p>
          Accesul, navigarea și / sau utilizarea acestui site web și a
          contractelor de achiziție a produselor prin intermediul acestuia vor
          fi reglementate de legislația romana. Orice controversă, problemă sau
          dezacord care apare sau are legătură cu accesul, navigarea și / sau
          utilizarea site-ului sau cu interpretarea și executarea acestor
          Condiții sau cu acordurile de vânzare între JOGGA și Utilizator, vor
          fi transmise către jurisdicția neexclusivă a instanțelor și
          tribunalelor române în jurisdicția societății JOGGA.
        </p>
        <h3>
          <strong>Reclamații</strong>
        </h3>
        <p>
          Utilizatorul poate trimite către JOGGA reclamațiile, revendicările sau
          orice alte comentarii pe care dorește să le facă prin intermediul
          informațiilor de contact furnizate la începutul acestor Condiții
          (Informații generale). Utilizatorul în calitate de consumator poate
          solicita o soluționare extrajudiciară a litigiilor, în conformitate cu
          Regulamentul UE nr. 524/2013 al Parlamentului European și al
          Consiliului, din 21 mai 2013, privind soluționarea litigiilor online
          în materie de consum. Puteți accesa această metodă prin intermediul
          site-ului web: http://ec.europa.eu/consumers/odr/
        </p>
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 pb-10 ">
      <Helmet>
        <title>Termeni și condiții</title>
      </Helmet>
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
      </div>
    </div>
  );
};

export default Terms;
