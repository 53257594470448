import Label from "components/Label/Label";
import { FC, useRef, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { Helmet } from "react-helmet";
import { useStoreActions, useStoreState } from "store";
import Autocomplete from "react-google-autocomplete";
import { useTranslation } from "react-i18next";
import { sportsJogga } from "containers/PageHome/PageHome";
import { Formik } from "formik";
import * as Yup from "yup";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate } from "react-router-dom";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();
  const [selectedSport, setSelectedSport] = useState("");
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("Acest câmp este obligatoriu"),
    lastName: Yup.string().trim().required("Acest câmp este obligatoriu"),
    phone: Yup.string().trim().required("Acest câmp este obligatoriu"),
  });
  const { user, isAuthenticated } = useStoreState((store) => store.auth);
  const { editUser } = useStoreActions((store) => store.auth);
  const fileInput = useRef(null);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Editeaza contul</title>
      </Helmet>
      <div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">{t("account.editAccount")}</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <Formik
            initialValues={{
              firstName: user.firstName || "",
              lastName: user.lastName || "",
              phone: user.phone || "",
              image: user.imageUser,
              imageUserFile: "",
              placeName: user.placeName || "",
              position: user.position,
              preferredSports:
                user.preferredSports && user.preferredSports.length
                  ? user.preferredSports?.map((sport) => sport.idSport)
                  : [],
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              const saveData = {
                firstName: values.firstName,
                lastName: values.lastName,
                preferredSports: values.preferredSports.map((sport) => ({
                  idSport: sport,
                })),
                maximumDistance: 100, // harcoded
                placeName: values.placeName,
                userLanguage: i18n.resolvedLanguage,
                country: "RO", // harcoded,
                isCurrentLocation: false,
                phone: values.phone,
                position: values.position,
                imageUser: values.imageUserFile,
              };
              navigation("/profile");
              await editUser(saveData);
              console.log(saveData);
              setSubmitting(false);
            }}
          >
            {({
              errors,
              touched,
              values,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <div className="flex flex-col md:flex-row">
                <div className="flex-shrink-0 flex items-start">
                  <div className="relative rounded-full overflow-hidden flex">
                    <Avatar
                      imgUrl={values.image}
                      userName={user.firstName}
                      sizeClass="w-32 h-32"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                          stroke="currentColor"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <span className="mt-1 text-xs">Change Image</span>
                    </div>
                    <input
                      accept="image/*"
                      onChange={(el) => {
                        if (!el?.target?.files) {
                          return;
                        }
                        console.log("aa");
                        const file = el.target.files[0];
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          console.log("aaa");
                          setFieldValue("image", reader.result);
                        };
                        reader.onerror = function (error) {
                          console.log("Error: ", error);
                        };
                        setFieldValue("imageUserFile", file);
                      }}
                      ref={fileInput}
                      type="file"
                      className="absolute inset-0 opacity-0 cursor-pointer"
                    />
                  </div>
                </div>

                <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
                  <div>
                    <Label>{t("userFiels.lastName")}</Label>
                    <Input
                      type="text"
                      noShowError={true}
                      value={values.lastName}
                      error={errors.lastName}
                      touched={touched.lastName}
                      placeholder={t("userFiels.lastName")}
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <Label>{t("userFiels.firstName")}</Label>
                    <Input
                      type="text"
                      noShowError={true}
                      value={values.firstName}
                      error={errors.firstName}
                      touched={touched.firstName}
                      placeholder={t("userFiels.firstName")}
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {/* ---- */}
                  <div className=" border-b border-neutral-200 dark:border-neutral-700"></div>
                  <span className="block text-lg font-semibold">
                    {t("account.favoriteSports")}
                  </span>
                  <div className="flow-root">
                    <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
                      {values.preferredSports?.map((sport) => (
                        <div
                          key={sport}
                          className="flex items-center justify-between py-3"
                        >
                          <span className="text-neutral-6000 dark:text-neutral-400 font-medium">
                            {t(`sports.${sport}`)}
                          </span>
                          <button
                            onClick={() => {
                              const newPreferredSports =
                                values.preferredSports.filter(
                                  (prefSport) => prefSport !== sport
                                );
                              setFieldValue(
                                "preferredSports",
                                newPreferredSports
                              );
                            }}
                          >
                            <i className="text-2xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"></i>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                    <Select
                      className="mt-1.5"
                      onChange={(el) => {
                        setSelectedSport(el.target.value);
                      }}
                    >
                      <option value=""> {t("account.addSport")}</option>
                      {sportsJogga.map((sport) => (
                        <option key={sport.key} value={sport.key}>
                          {t(sport.name)}
                        </option>
                      ))}
                    </Select>
                    {selectedSport ? (
                      <ButtonSecondary
                        className="flex-shrink-0"
                        onClick={() => {
                          if (
                            selectedSport &&
                            !values.preferredSports.includes(selectedSport)
                          ) {
                            setFieldValue("preferredSports", [
                              ...values.preferredSports,
                              selectedSport,
                            ]);
                          }
                          setSelectedSport("");
                        }}
                      >
                        <i className="text-xl las la-plus"></i>
                        <span className="ml-3">{t("account.addSport")}</span>
                      </ButtonSecondary>
                    ) : null}
                  </div>

                  {/* ---- */}
                  <div>
                    <Label>{t("account.chooseCity")}</Label>
                    <Autocomplete
                      style={{
                        borderWidth: 1,
                      }}
                      language={i18n.resolvedLanguage}
                      className="border-neutral-200 dark:border-neutral-700 block w-full focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5"
                      onPlaceSelected={(selected, a, c) => {
                        setFieldValue("placeName", selected.formatted_address);
                        setFieldValue("position", {
                          type: "Point",
                          coordinates: [
                            selected.geometry.location.lng(),
                            selected.geometry.location.lat(),
                          ],
                        });
                      }}
                      defaultValue={values.placeName}
                    />
                  </div>
                  {/* ---- */}
                  <div>
                    <Label>{t("userFiels.phone")}</Label>
                    <Input
                      className="mt-1.5"
                      type="text"
                      noShowError={true}
                      value={values.phone}
                      error={errors.phone}
                      touched={touched.phone}
                      placeholder={t("userFiels.phone")}
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <ButtonPrimary onClick={() => handleSubmit()}>
                      {t("account.save")}
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
