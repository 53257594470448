import React, { FC, ReactElement, ReactNode } from "react";
import { Carousel } from "react-responsive-carousel";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useWindowSize from "../../hooks/useWindowResize";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";

export interface SectionHeroFeaturesAppProps {
  className?: string;
  images: {
    src: string;
    alt: string;
  }[];
  positionImg?: string;
  heading: ReactNode;
  subHeading: ReactElement;
  btnText: string;
}

const SectionHeroFeaturesApp: FC<SectionHeroFeaturesAppProps> = ({
  className = "",
  images,
  positionImg = "right",
  heading,
  subHeading,
  btnText,
}) => {
  const windowSize = useWindowSize();
  const { t } = useTranslation();
  return (
    <div
      className={`nc-SectionHero relative ${className} mt-6 mb-6`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        {positionImg === "left" && windowSize.width > 990 ? (
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            width={200}
          >
            {images?.map((image, index) => (
              <img key={index} src={image.src} alt={image.alt} />
            ))}
          </Carousel>
        ) : null}
        <div className="w-screen max-w-full space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
          {!!btnText && <ButtonPrimary href="/login">{btnText}</ButtonPrimary>}
        </div>
        {positionImg === "right" || windowSize.width <= 990 ? (
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            width={200}
          >
            {images?.map((image, index) => (
              <img key={index} src={image.src} alt={t(image.alt)} />
            ))}
          </Carousel>
        ) : null}
      </div>
    </div>
  );
};

export default SectionHeroFeaturesApp;
