import { FC } from "react";
import SearchForm from "../HeroSearchForm/SearchForm";
import { LocationsCity } from "../../containers/PageHome/PageHome";
import { useTranslation } from "react-i18next";

export interface SectionHeroProps {
  className?: string;
  locationsCity: LocationsCity[];
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  locationsCity,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${className} mb-16 items-center justify-items-center`}
      data-nc-id="SectionHero"
    >
      <div className={"items-center justify-items-center"}>
        <h1 className="text-center pt-10 pb-5 text-3xl md:text-4xl font-semibold text-zinc-50">
          {t("home.title")}
        </h1>
      </div>
      <div className="flex justify-center w-full flex flex-1 ">
        <SearchForm
          cityName={locationsCity[0].name}
          locationsCity={locationsCity}
          sportId={"tennis"}
        />
      </div>
    </div>
  );
};

export default SectionHero;
