import { Tab } from "@headlessui/react";
import CommentListing from "components/CommentListing/CommentListing";
import { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Helmet } from "react-helmet";
import { useStoreState } from "store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import api from "api";
import { LOCATION } from "api/apiRoutes";
import {
  FavoriteAddress,
  MyBookings,
} from "containers/LocationDetailsPage/types";
import LocationCardH from "components/LocationCardH/LocationCardH";
import BookingCard from "components/BookingCard/BookingCard";
import { useQuery } from "hooks/useQuery";
import { useTranslation } from "react-i18next";

export interface ProfilePageProps {
  className?: string;
}
// let [categories] = useState(["Rezervari", "Favorite", "Portofel"]);
const ProfilePage: FC<ProfilePageProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const { user } = useStoreState((state) => state.auth);
  const [favoriteLocations, setFavoriteLocations] = useState<FavoriteAddress[]>(
    []
  );
  let path = useQuery();
  const tabSgtring = path.get("tab");

  const [myBookings, setMyBookings] = useState<MyBookings[]>([]);

  useEffect(() => {
    fetchFavoriteLocation();
    fetchMyBookings();
  }, []);
  const fetchFavoriteLocation = async () => {
    let { data } = await api.get(LOCATION.MY_BOOKINGS);
    setMyBookings(data);
  };
  const fetchMyBookings = async () => {
    let { data } = await api.get(LOCATION.FAVORITE_ADDRESS);
    setFavoriteLocations(data);
  };
  const navigate = useNavigate();

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          sizeClass="w-28 h-28"
          imgUrl={user.imageUser}
          userName={user.firstName}
        />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">
            {user.firstName} {user.lastName}
          </h2>
        </div>

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>
        <ButtonPrimary
          className="w-36 py-2 px-2"
          onClick={() => navigate("/account")}
        >
          {t("userFiels.edit")}
        </ButtonPrimary>
        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {user.placeName}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{t("ProfilePage.title")}</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group selectedIndex={Number(tabSgtring)}>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {[
                t("ProfilePage.bookings"),
                t("ProfilePage.favoriteLocations"),
              ].map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "backgroundJogga text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8  sm:grid-cols-2">
                  {myBookings.map((booking) => (
                    <BookingCard key={booking._id} data={booking} />
                  ))}
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8  sm:grid-cols-2">
                  {favoriteLocations.length ? (
                    favoriteLocations.map((favoriteAddres) => (
                      <Link
                        key={favoriteAddres.location._id}
                        to={`/${
                          favoriteAddres.location.seoUrl
                            ? favoriteAddres.location.seoUrl
                            : favoriteAddres.location._id
                        }`}
                        className="relative w-full"
                      >
                        <LocationCardH
                          key={favoriteAddres._id}
                          location={favoriteAddres.location}
                        />
                      </Link>
                    ))
                  ) : (
                    <div>{t("ProfilePage.noFavoriteLocation")}</div>
                  )}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Profilul meu</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
        </div>
      </main>
    </div>
  );
};

export default ProfilePage;
