import { FC } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import * as Yup from "yup";
import { Formik } from "formik";
import { useStoreActions } from "store";
import SocialLoginBtn from "components/SocialLoginBtn/SocialLoginBtn";
import { useTranslation } from "react-i18next";

export interface PageLoginProps {
  className?: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Trebuie sa fie un email valid")
    .required("Acest câmp este obligatoriu"),
  password: Yup.string().trim().required("Acest câmp este obligatoriu"),
});

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const { userLogin } = useStoreActions((actions) => actions.auth);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Authentica-te | Conecteaza-te la contul tau Jogga</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("nav.login")}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <SocialLoginBtn />
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              {t("nav.or")}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              userLogin({
                email: values.email.toLocaleLowerCase().trim(),
                password: values.password,
              });
              setSubmitting(false);
            }}
          >
            {({
              errors,
              values,
              setFieldValue,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    {t("userFiels.email")}
                  </span>
                  <Input
                    type="text"
                    error={errors.email}
                    touched={touched.email}
                    placeholder={t("userFiels.email")}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    {t("userFiels.password")}
                    <Link to="/forgot-password" className="text-sm">
                      {t("userFiels.forgotPassword")}
                    </Link>
                  </span>
                  <Input
                    type="password"
                    error={errors.password}
                    touched={touched.password}
                    placeholder={t("userFiels.password")}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </label>
                <div className="flex-1 ">
                  <ButtonPrimary className="w-full" onClick={handleSubmit}>
                    {t("userFiels.btnContinue")}
                  </ButtonPrimary>
                </div>
              </>
            )}
          </Formik>

          {/* ==== */}
          <p className="my-20 flex items-center text-xl leading-[115%] md:text-xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            <Link to="/signup">
              {t("userFiels.noAccountText")}{" "}
              <span className="underline"> {t("userFiels.hear")}</span>{" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
