import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import { PathName } from "routers/types";
import NcImage from "../../shared/NcImage/NcImage";
import Football from "../../images/sports/categ-1.png";
import Tennis from "../../images/sports/categ-3.png";
import TableTennis from "../../images/sports/categ-4.png";
import AllSports from "../../images/sports/all-categ.png";
import { sportsJogga } from "../../containers/PageHome/PageHome";
import ButtonSubmit from "./ButtonSubmit";
import ArrowDown from "./ArrowDown";
import { useTranslation } from "react-i18next";

const Assets: any = {
  all: AllSports,
  football: Football,
  tennis: Tennis,
  tableTennis: TableTennis,
};
export interface SportSelectProps {
  selectedValue: string;
  values: string[];
  onChange: (data: string) => void;
  fieldClassName?: string;
  className?: string;
  disabled?: boolean;
  onPresSubmit?: () => void;
  hasButtonSubmit?: boolean;
}

const SportSelect: FC<SportSelectProps> = ({
  selectedValue,
  values,
  disabled = false,
  onPresSubmit,
  hasButtonSubmit,
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
}) => {
  const { t } = useTranslation();
  const getSportName = (selectedValue: any) => {
    const sportFind = sportsJogga.find(
      (sports) => sports.key === selectedValue
    );
    if (sportFind) {
      return sportFind.name;
    }
    return "";
  };
  return (
    <Popover className={`flex relative ${className}`}>
      {({ open, close }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none cursor-pointer ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`flex-1 flex text-left items-center ${fieldClassName} space-x-2 `}
              disabled={disabled}
              onClick={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <NcImage
                  containerClassName="w-9 h-9 rounded-lg overflow-hidden relative flex"
                  src={Assets[selectedValue]}
                />
              </div>
              <div className="flex-grow">
                <span className="block mt-1 text-sm text-zinc-50 leading-none font-light">
                  {t("home.sportInput")}
                </span>
                <span className="block xl:text-lg font-semibold text-zinc-50">
                  {t(getSportName(selectedValue))}
                </span>
              </div>
            </Popover.Button>

            <ArrowDown />
          </div>
          {/*BUTTON SUBMIT OF FORM*/}
          {hasButtonSubmit && (
            <div className="pr-2 xl:pr-4">
              <ButtonSubmit onClick={onPresSubmit} />
            </div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 z-10 w-full sm:min-w-[200px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <ul className="">
                {values.map((sport, index) => {
                  const active = sport === selectedValue;
                  return (
                    <li
                      onClick={() => {
                        onChange(sport);
                        close();
                      }}
                      className={`flex items-center cursor-pointer text-sm lg:text-base font-medium pt-3 pb-3 ${
                        active
                          ? ""
                          : "text-neutral-500 hover:text-neutral-100 dark:hover:text-neutral-700 hover:bg-gray-800 dark:hover:bg-gray-100"
                      } `}
                      key={index}
                    >
                      {active ? (
                        <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
                      ) : (
                        <div style={{ width: 20, height: 10 }}> </div>
                      )}
                      <span>{t(getSportName(sport))}</span>
                    </li>
                  );
                })}
              </ul>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default SportSelect;
