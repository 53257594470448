import { FacebookProvider, LoginButton } from "react-facebook";
import facebookSvg from "images/Facebook.svg";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { FC } from "react";
import { useStoreActions } from "../../store";
import Toast from "../Toast/Toast";
import { useTranslation } from "react-i18next";

const SocialLoginBtn: FC = () => {
  const { t } = useTranslation();
  const { loginWithFacebook, loginWithGoogle } = useStoreActions(
    (actions) => actions.auth
  );
  const handleSuccess = (response: any) => {
    if (response.status) {
      loginWithFacebook({ token: response.authResponse.accessToken });
    }
  };

  const handleError = (error: any) => {
    console.log(error);
    Toast("error", t("error.loginFacebook"));
  };
  return (
    <div className="grid gap-3">
      <FacebookProvider appId="479187820268719" lazy>
        <LoginButton
          scope="email"
          onError={handleError}
          onSuccess={handleSuccess}
        >
          <div className="nc-will-change-transform border-1 flex w-full rounded-sm bg-white px-4 py-2 transform transition-transform sm:px-6 hover:bg-neutral-50">
            <img
              className="flex-shrink-0"
              src={facebookSvg}
              alt={"Sign in Facebook"}
            />
            <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 sm:text-sm">
              Sign in Facebook
            </h3>
          </div>
        </LoginButton>
      </FacebookProvider>
      <GoogleOAuthProvider clientId="637347045082-uf7vuaufmn4rujcuhua9e0ii8v6sblbm.apps.googleusercontent.com">
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            if (credentialResponse.credential && credentialResponse.clientId) {
              loginWithGoogle({
                googleToken: credentialResponse.credential,
                clientId: credentialResponse.clientId,
              });
            }
          }}
          onError={() => {
            Toast("error", t("error.loginGoogle"));
          }}
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default SocialLoginBtn;
