import { createStore, createTypedHooks, persist } from "easy-peasy";

import model, { StoreModel } from "../models";
import { setStore } from "../api";

const { useStoreActions, useStoreDispatch, useStoreState } = createTypedHooks<
  StoreModel
>();

export { useStoreActions, useStoreDispatch, useStoreState };

const store = createStore(persist(model));

setStore(store);

export default store;
