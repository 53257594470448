import { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Formik } from "formik";
import moment from "moment";
import {
  BookingDateSelected,
  CardProp,
  LocationDetails,
  SelectedBooking,
} from "./types";
import * as Yup from "yup";
import api from "../../api";
import { AUTH, LOCATION } from "../../api/apiRoutes";
import Toast from "../../components/Toast/Toast";
import Checkbox from "../../shared/Checkbox/Checkbox";
import { useStoreState } from "store";
import CardAuthorBox from "components/CardUser/CardUser";
import { DEMO_AUTHORS } from "data/authors";
import { useTranslation } from "react-i18next";
export interface CheckOutPageProps {
  className?: string;
  paymentType: string;
  selectedBooking: SelectedBooking;
  selectedDate: moment.Moment | null;
  locationData: LocationDetails | null;
  setBookingDateSelected: (payload: BookingDateSelected | null) => void;
  setPaymentType: (payload: string) => void;
}
const validationSchema = Yup.object().shape({
  email: Yup.string().trim().email().required(),
  firstName: Yup.string().trim().required(),
  lastName: Yup.string().trim().required(),
  phone: Yup.string().trim().required(),
});

const params: any = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop: string) => {
    return searchParams.get(prop);
  },
});

const CheckOutPage: FC<CheckOutPageProps> = ({
  className = "",
  selectedBooking,
  selectedDate,
  locationData,
  setBookingDateSelected,
  paymentType,
  setPaymentType,
}) => {
  const { t } = useTranslation();
  const { user } = useStoreState((state) => state.auth);
  const [userCards, setUserCards] = useState<CardProp[] | []>([]);
  const [payemntCard, setPayemntCard] = useState("");

  useEffect(() => {
    if (user?._id) {
      fetchUserCards();
    }
  }, [user]);
  const fetchUserCards = async () => {
    const { data } = await api.get(AUTH.USER_CARDS);
    setUserCards(data);
  };

  const [htmlPay, setHtmlPay] = useState("");
  const hourInterval = locationData?.hourInterval
    ? locationData?.hourInterval
    : 0.5;
  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 px-0 sm:p-6 xl:p-8">
        <div className={"flex flex-row justify-center align-middle mb-4"}>
          <h2 className="text-2xl lg:text-2xl font-semibold textCenter">
            {selectedDate?.format("dddd")}
            <span
              style={{
                padding: 5,
                borderWidth: 1,
                borderRadius: 20,
                marginRight: 5,
                marginLeft: 5,
                borderColor: "#000",
              }}
            >
              {selectedDate?.format("DD")}
            </span>
            {selectedDate?.format("MMM")}
          </h2>
        </div>

        <div className="border-b border-neutral-200 dark:border-neutral-700" />
        {selectedBooking && selectedBooking.books.length && locationData ? (
          <p className={"textCenter text-[16px] font-semibold"}>
            {t("LocationDetailsPage.field")}:
            {
              locationData?.fields.find(
                (field) => field._id === selectedBooking.books[0].fieldId
              )?.fieldName
            }
          </p>
        ) : null}

        <div>
          {/*<span className="block font-normal text-2xl py-3 textCenter">Durata</span>*/}
          <div className="flex flex-row justify-center py-3">
            <button
              className={`p-4 rounded-2xl bg-gray-700`}
              onClick={() => {
                if (
                  selectedBooking.books.length * hourInterval - hourInterval >=
                  1
                ) {
                  setBookingDateSelected({
                    book: selectedBooking.initialBook,
                    numberOfHour:
                      selectedBooking.books.length * hourInterval -
                      hourInterval,
                  });
                }
              }}
            >
              <span className={"bold text-2xl text-zinc-50"}>-</span>
            </button>
            <div className="border rounded-2xl mr-1 ml-1">
              <span className="block text-2xl font-semibold px-3 textCenter">
                {selectedBooking.books.length * hourInterval}{" "}
                {t("LocationDetailsPage.hour")}
              </span>
              <span className="block text-1xl font-semibold px-3 textCenter">
                <span className="font-normal text-1xl textCenter">
                  {t("LocationDetailsPage.form")}
                </span>{" "}
                {moment(selectedBooking.books[0].bookDate).format("HH:mm")}{" "}
                <span className="font-normal text-1xl textCenter">
                  {t("LocationDetailsPage.to")}
                </span>{" "}
                {moment(
                  selectedBooking.books[selectedBooking.books.length - 1]
                    .bookDate
                )
                  .clone()
                  .add(hourInterval, "hours")
                  .format("HH:mm")}
              </span>
            </div>
            <button
              className={`p-4 bg-gray-700 rounded-2xl`}
              onClick={() =>
                setBookingDateSelected({
                  book: selectedBooking.initialBook,
                  numberOfHour:
                    selectedBooking.books.length * hourInterval + hourInterval,
                })
              }
            >
              <span className={"bold text-2xl text-zinc-50"}>+</span>
            </button>
          </div>

          <div className="flex flex-col space-y-4">
            <div className="border-b border-neutral-200 dark:border-neutral-700" />
            <div className="flex justify-center font-semibold">
              <span className={"text-2xl pr-2"}>
                {t("LocationDetailsPage.total")}:
              </span>
              <div>
                {selectedBooking &&
                selectedBooking?.totalPriceNonDiscounted >
                  selectedBooking?.totalPrice ? (
                  <s className={"pr-2 text-1xl"}>
                    {selectedBooking?.totalPriceNonDiscounted} Lei
                  </s>
                ) : null}
                <span className={"text-2xl "}>
                  {selectedBooking?.totalPrice} Lei
                </span>
              </div>
            </div>
            <div className="flex justify-center font-semibold">
              {locationData?.acceptAdvancePayment ||
              locationData?.acceptSplitBill ? (
                <div
                  className={`flex-1 border border-neutral-300 dark:border-neutral-600 ${
                    paymentType === "full" ? "bg-gray-700 text-white" : ""
                  } hover:border-neutral-700  bg-white rounded-md m-2`}
                  onClick={() => setPaymentType("full")}
                >
                  <p className={"text-md text-center "}>
                    {t("LocationDetailsPage.fullPaymentTitle")}
                  </p>
                  <p className={"text-lg text-center "}>
                    {selectedBooking?.totalPrice} Lei
                  </p>
                  <p className={"text-sm text-center "}>
                    {t("LocationDetailsPage.fullPaymentSubTitle")}
                  </p>
                </div>
              ) : null}

              {locationData?.acceptAdvancePayment ? (
                <div
                  className={`flex-1 border border-neutral-300 dark:border-neutral-600 ${
                    paymentType === "partial" ? "bg-gray-700 text-white" : ""
                  } hover:border-neutral-700  bg-white rounded-md m-2`}
                  onClick={() => setPaymentType("partial")}
                >
                  <p className={"text-md text-center "}>
                    {t("LocationDetailsPage.acceptAdvancePaymentTitle")}
                  </p>
                  <p className={"text-lg text-center "}>
                    {selectedBooking?.totalAdvancePayment} Lei
                  </p>
                  <p className={"text-sm text-center "}>
                    {t("LocationDetailsPage.acceptAdvancePaymentSubTitle", {
                      payment:
                        selectedBooking?.totalPrice -
                        selectedBooking?.totalAdvancePayment,
                    })}
                  </p>
                </div>
              ) : null}
              {/* {locationData?.acceptSplitBill ? (
                <div
                  className={`flex-1 border border-neutral-300 dark:border-neutral-600 ${
                    paymentType === "splitBill" ? "bg-gray-700 text-white" : ""
                  } hover:border-neutral-700  bg-white rounded-md m-2`}
                  onClick={() => setPaymentType("splitBill")}
                >
                  <p className={"text-md text-center "}>Totdo </p>
                  <p className={"text-lg text-center "}>
                    {" "}
                    {selectedBooking?.totalAdvancePayment} Lei
                  </p>
                  <p className={"text-sm text-center "}>
                    {" "}
                    Restul de{" "}
                    {selectedBooking?.totalPrice -
                      selectedBooking?.totalAdvancePayment}{" "}
                    de lei la locatie
                  </p>
                </div>
              ) : null} */}
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-1xl font-semibold mt-6">
            {t("LocationDetailsPage.billingDetails")}
          </h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
          <Formik
            initialValues={{
              firstName: user?.firstName ? user.firstName : "",
              lastName: user?.lastName ? user.lastName : "",
              email: user?.email ? user.email : "",
              phone: user?.phone ? user.phone : "",
              terms: true,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              if (!values.terms) {
                return setFieldError("terms", "Este necesar");
              }
              try {
                const { data } = await api.post(LOCATION.LOCATION_BOOKINGS, {
                  ...values,
                  ...selectedBooking,
                  embedded: !!params?.embeded,
                  paymentType,
                  timezone: moment().utcOffset(),
                  cardId: payemntCard,
                });
                setHtmlPay(data.form);
                setTimeout(() => {
                  if (document) {
                    // @ts-ignore
                    document.getElementById("paymentForm").submit();
                  }
                }, 300);
              } catch (e) {
                Toast(
                  "error",
                  "A aparut o eroare, va rugam incercati mai tarziu!"
                );
              }
              setSubmitting(false);
            }}
          >
            {({
              errors,
              values,
              setFieldValue,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <div className="mt-6">
                <div className="space-y-2">
                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>
                        {t("userFiels.firstName")}
                        <span className={"text-rose-600"}>*</span>
                      </Label>
                      <Input
                        type="text"
                        value={values.firstName}
                        error={errors.firstName}
                        touched={touched.firstName}
                        noShowError={true}
                        placeholder={t("userFiels.firstName")}
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>
                        {t("userFiels.lastName")}
                        <span className={"text-rose-600"}>*</span>
                      </Label>
                      <Input
                        type="text"
                        placeholder={t("userFiels.lastName")}
                        value={values.lastName}
                        noShowError={true}
                        error={errors.lastName}
                        touched={touched.lastName}
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>
                      {t("userFiels.email")}
                      <span className={"text-rose-600"}>*</span>{" "}
                      <span>{user.email}</span>
                    </Label>
                    {!user.email ? (
                      <Input
                        placeholder={t("userFiels.email")}
                        name="email"
                        disabled={!!user.email}
                        value={values.email}
                        noShowError={true}
                        onChange={handleChange}
                        error={errors.email}
                        touched={touched.email}
                      />
                    ) : null}
                  </div>
                  <div className="space-y-1">
                    <Label>
                      {t("userFiels.phone")}
                      <span className={"text-rose-600"}>*</span>
                    </Label>
                    <Input
                      placeholder={t("userFiels.phone")}
                      name="phone"
                      onChange={handleChange}
                      value={values.phone}
                      error={errors.phone}
                      touched={touched.phone}
                    />
                  </div>
                  {user?._id ? (
                    <div>
                      <h3 className="text-1xl font-semibold mt-6">
                        {t("LocationDetailsPage.paymentMethodTitle")}
                      </h3>
                      <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">
                        {t("LocationDetailsPage.paymentMethodDescription")}
                      </p>
                      <div className="py-3">
                        {userCards.map((card) => {
                          return (
                            <CardAuthorBox
                              selectedCard={payemntCard}
                              key={card._id}
                              cardData={card}
                              setPayemntCard={(cardId) => {
                                if (payemntCard === cardId) {
                                  setPayemntCard("");
                                } else {
                                  setPayemntCard(cardId);
                                }
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  ) : null}

                  <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
                  <div className="">
                    <Checkbox
                      name={"terms"}
                      onChange={(val) => setFieldValue("terms", val)}
                      label={t("LocationDetailsPage.terms")}
                      subLabel={t("LocationDetailsPage.termsSubLabel")}
                      defaultChecked={values.terms}
                    />
                    {errors.terms ? (
                      <span className="pl-3 text-center text-neutral-6000 dark:text-neutral-300 text-rose-600 text-sm">
                        {errors.terms}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="pt-8 flex justify-center">
                  <ButtonPrimary
                    className={"w-full md:w-80"}
                    onClick={handleSubmit}
                  >
                    {t("LocationDetailsPage.btnPay", {
                      total:
                        paymentType === "full"
                          ? selectedBooking?.totalPrice
                          : selectedBooking?.totalAdvancePayment,
                    })}
                  </ButtonPrimary>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-4 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full ">{renderMain()}</div>
      </main>
      <div className="content" dangerouslySetInnerHTML={{ __html: htmlPay }} />
    </div>
  );
};

export default CheckOutPage;
