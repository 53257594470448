import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import moment from "moment";

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "ro",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });

moment.locale("ro", {
  months:
    "ianuarie_februarie_martie_aprilie_mai_iunie_iulie_august_septembrie_octombrie_noiembrie_decembrie".split(
      "_"
    ),
  monthsShort:
    "ian._feb._mart._apr._mai_iun._iul._aug_sept._oct._nov._dec.".split("_"),
  monthsParseExact: true,
  weekdays: "Duminica_Luni_Marti_Miercuri_Joi_Vineri_Sambata".split("_"),
  weekdaysShort: "dum._lun._mar._mie._joi._vin._sam.".split("_"),
  weekdaysMin: "Du_Lu_Ma_Mi_Jo_Vi_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Astăzi la] LT",
    nextDay: "[Mâine la] LT",
    nextWeek: "dddd [la] LT",
    lastDay: "[Ieri la] LT",
    lastWeek: "dddd [ultimul la] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "in %s",
    past: "din %s",
    s: "câteva secunde",
    m: "un minut",
    mm: "%d minute",
    h: "o ora",
    hh: "%d ore",
    d: "o zi",
    dd: "%d zile",
    M: "o luna",
    MM: "%d luni",
    y: "un an",
    yy: "%d ani",
  },
});
// Todo aici
moment.locale("ro");
// new usage
// i18n.services.formatter.add('DATE_HUGE', (value, lng, options) => {
//   return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime.DATE_HUGE)
// });

export default i18n;
