import React from "react";
import { Link } from "react-router-dom";
import LogoSvgLight from "./LogoSvgLight";
import LogoSvgMobile from "./LogoSvgMobile";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  className = "w-24",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      <div className="hidden xl:flex items-center space-x-0.5">
        <div className="inline-block dark:hidden">
            <img src={require("../../images/Jogga-round-text-light.png")} style={{
              maxWidth: '120%'
            }} alt="Jogga logo" />
        </div>
        <div className="hidden dark:inline-block">
          <img src={require("../../images/Jogga-round-text.png")} style={{
            maxWidth: '120%'
          }} alt="Jogga logo"  />
        </div>
            {/*<LogoSvgLight />*/}
      </div>
      <div className="flex xl:hidden items-center">
        <div className="inline-block dark:hidden">
          <img src={require("../../images/Jogga-round-text-light.png")} style={{
            maxWidth: '110%'
          }} alt="Jogga logo" />
        </div>
        <div className="hidden dark:inline-block">
          <img src={require("../../images/Jogga-round-text.png")} style={{
            maxWidth: '110%'
          }} alt="Jogga logo" />
        </div>
        {/*<LogoSvgMobile />*/}
      </div>
    </Link>
  );
};

export default Logo;
