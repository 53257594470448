import { FC, useEffect, useState } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import api from "../../api";
import { LOCATION } from "../../api/apiRoutes";
import { CityPosition, NearbyLocations } from "../LocationDetailsPage/types";
import { LocationsCity } from "../PageHome/PageHome";
import FullPageLoader from "../FullPageLoader";
import Button from "../../shared/Button/Button";
import { useTranslation } from "react-i18next";

export interface ListingStayMapPageProps {
  className?: string;
}
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const capitalizeFirstLetter = (name: string | undefined) => {
  if (!name) return "";
  return name.charAt(0).toUpperCase() + name.slice(1);
};
const ListingLocationsCityPage: FC<ListingStayMapPageProps> = ({
  className = "",
}) => {
  let { cityName } = useParams();
  let { sportName } = useParams();
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState(
    getWindowDimensions().width > 760
  );
  let cityNameCapitalize = capitalizeFirstLetter(cityName);
  const [locations, setLocations] = useState<NearbyLocations[] | undefined>();
  const [city, setCity] = useState<CityPosition | undefined>();
  const [locationsCity, setLocationsCity] = useState<LocationsCity[] | []>([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(LOCATION.GET_LOCATIONS, {
        params: {
          city: cityName,
          sportName: sportName ? sportName : "all",
        },
      });
      setLocations(data.locations);
      setCity(data.city);
    };

    fetchData();

    const fetchCity = async () => {
      const { data } = await api.get(LOCATION.GET_LOCATIONS_CITY);
      const formatCategory = data.map(
        (el: {
          _id: string;
          seoUrl: string;
          cityName: string;
          imageUrl: string;
        }) => ({
          id: el._id,
          href: `/city/${el.seoUrl}`,
          name: el.cityName,
          taxonomy: "category",
          thumbnail: el.imageUrl,
        })
      );
      setLocationsCity(formatCategory);
    };
    fetchCity();
  }, [cityName, sportName]);

  if (!locations || !city) {
    return <FullPageLoader />;
  }

  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>
          Cauti {sportName ? `terenuri de ${sportName}` : "locatii sportive"} in{" "}
          {cityNameCapitalize} ?
        </title>
        <meta
          name="description"
          content="Gaseste pe Jogga cele mai bune locatii unde poti sa faci sport"
        />
      </Helmet>
      <BgGlassmorphism />
      <div className={"flex row justify-center ml-3 mr-3 md:hidden"}>
        <Button
          className={"w-full backgroundJogga  mt-3"}
          onClick={() => {
            setShowFilters(!showFilters);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-zinc-50"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
            />
          </svg>
          <span className="font-medium text-zinc-50">
            {t("ListingLocationsCityPage.filters")}
          </span>
        </Button>
      </div>
      <div className="pb-2">
        {city && showFilters ? (
          <SectionHeroArchivePage
            cityName={city.cityName}
            countryName={city.country}
            rightImage={city.imageUrl}
            locationsCity={locationsCity}
            locations={locations}
          />
        ) : null}
      </div>

      {/* SECTION */}
      <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
        {locations && city ? (
          <SectionGridHasMap
            locations={locations}
            city={city}
            heading={t("ListingLocationsCityPage.heading", {
              cityName: city?.cityName,
            })}
            subHeading={t("ListingLocationsCityPage.subHeading")}
          />
        ) : null}
      </div>

      <div className="container overflow-hidden">
        <SectionSubscribe2 className="py-24 lg:py-28" />
      </div>
    </div>
  );
};

export default ListingLocationsCityPage;
