import React from "react";
import * as animationData from '../images/loading.json'
import Lottie from "lottie-react";
export default function FullPageLoader() {
  return (
    <div style={{
        zIndex: 999
    }} className="absolute top-0 bottom-0 right-0 left-0 backgroundJogga w-screen h-screen flex items-center justify-center">
        <Lottie animationData={animationData} loop={true}  height={400}  width={400} />
    </div>
  );
}
