import React, { FC, useState } from "react";
import LocationInput from "./LocationInput";
import SportSelect from "./SportSelect";
import { allSports, LocationsCity } from "../../containers/PageHome/PageHome";
import { useNavigate } from "react-router-dom";
import ButtonSubmit from "./ButtonSubmit";
import ExperiencesDateSingleInput from "./ExperiencesDateSingleInput";
import moment from "moment";
import { useTranslation } from "react-i18next";

export type SearchTab = "Stays" | "Experiences" | "Cars" | "Flights";

export interface SearchFormProps {
  className?: string;
  cityName?: string;
  sportSelected?: string;
  sportId?: string;
  locationsCity?: LocationsCity[];
}

const SearchForm: FC<SearchFormProps> = ({
  className = "",
  cityName = "",
  locationsCity = [],
  sportId = "all",
}) => {
  const { t } = useTranslation()
  const [localCityName, setLocalCityName] = useState(cityName);
  const [sportSelected, setSportSelected] = useState(sportId);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    moment().startOf("day")
  );
  const navigate = useNavigate();

  const changeDay = async (date: moment.Moment | null) => {
    const startDate = moment(date).startOf("day");
    setSelectedDate(startDate);
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full pr-2 pl-2 py-5 lg:py-0 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      <div className="listingSection__wrap__DayPickerRangeController flow-root">
        <form className="flex flex-col sm:flex-row ">
          <div className="flex-1 border border-zinc-50 rounded-3xl m-1">
            <LocationInput
              defaultValue={localCityName ? localCityName : ""}
              placeHolder={t("home.locationInput")}
              locationsCity={locationsCity}
              onChange={(e) => console.log(e)}
              onInputDone={(val) => setLocalCityName(val)}
              className="flex-[1]"
            />
          </div>
          <div className="flex-1 border border-zinc-50 rounded-3xl m-1">
            <SportSelect
              selectedValue={sportSelected}
              values={allSports}
              onChange={(data) => setSportSelected(data)}
              className="flex-1"
              hasButtonSubmit={false}
            />
          </div>
          <div className="flex-1 border border-zinc-50 rounded-3xl m-1">
            <ExperiencesDateSingleInput
              defaultValue={selectedDate}
              anchorDirection={"left"}
              fieldClassName="p-4"
              onChange={changeDay}
              className="h-full"
            />
          </div>
          <div className="pr-2 xl:pr-4 m-1  flex justify-center items-center">
            <ButtonSubmit
              onClick={() => {
                if (localCityName) {
                  const locationCityData = locationsCity?.find(
                    (locationsCity) => locationsCity.name === localCityName
                  );
                  if (locationCityData) {
                    let href = locationCityData?.href;
                    if (sportSelected && sportSelected !== "all") {
                      href = `${href}/${sportSelected}`;
                    }
                    navigate(href);
                  }
                }
              }}
            />
          </div>
        </form>
      </div>
      {/*<form className="relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">*/}
      {/*  <LocationInput*/}
      {/*      defaultValue={localCityName ? localCityName : ""}*/}
      {/*      locationsCity={locationsCity}*/}
      {/*      onChange={(e) => console.log(e)}*/}
      {/*      onInputDone={(val) => setLocalCityName(val)}*/}
      {/*      className="flex-[1]"*/}
      {/*  />*/}
      {/*  <SportSelect*/}
      {/*      selectedValue={sportSelected}*/}
      {/*      values={allSports}*/}
      {/*      onChange={(data) => setSportSelected(data)}*/}
      {/*      className="flex-[1.4]"*/}
      {/*      hasButtonSubmit={true}*/}
      {/*      onPresSubmit={() => {*/}
      {/*        if(localCityName){*/}
      {/*          const locationCityData = locationsCity?.find(locationsCity => locationsCity.name === localCityName)*/}
      {/*          if(locationCityData){*/}
      {/*            let href = locationCityData?.href*/}
      {/*            if(sportSelected && sportSelected !== "all"){*/}
      {/*              href = `${href}/${sportSelected}`*/}
      {/*            }*/}
      {/*            navigate(href)*/}
      {/*          }*/}
      {/*        }*/}
      {/*      }}*/}
      {/*  />*/}
      {/*</form>*/}
    </div>
  );
};

export default SearchForm;
