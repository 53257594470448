import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  error?: string;
  noShowError?: boolean;
  touched?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      error,
      noShowError,
      rounded = "rounded-2xl",
      children,
      touched,
      type = "text",
      ...args
    },
    ref
  ) => {
    const validationColor = error
      ? "border-rose-600 dark:border-rose-700"
      : "border-neutral-200 dark:border-neutral-700";
    const color = !touched
      ? "border-neutral-200 dark:border-neutral-700"
      : validationColor;
    return (
      <div>
        <input
          ref={ref}
          type={type}
          className={
            color +
            ` block w-full focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className}`
          }
          {...args}
        />
        {!noShowError ? (
          <span className="pl-3 text-center dark:text-rose-600 text-rose-600  text-sm">
            {error}
          </span>
        ) : null}
      </div>
    );
  }
);

export default Input;
// border-neutral-200
