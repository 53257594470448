import { action, Action, Thunk, thunk } from "easy-peasy";

import api from "../../api";
import { AUTH } from "../apiRoutes";
import Toast from "components/Toast/Toast";
import { history } from "utils/history";
import axios from "axios";

export interface PreferredSports {
  idSport: string;
  level?: number;
}
export interface MedalUser {
  medalUrl: string;
  medalId: string;
}
export interface User {
  _id?: string;
  country?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  userLanguage?: string | null;
  imageUser?: string;
  imageUserCard?: string;
  level?: number;
  points?: number;
  lives?: number;
  countGame?: number;
  countGroups?: number;
  countFriends?: number;
  countChallenges?: number;
  sendReviewApp?: boolean;
  currentDaySeries?: number;
  maxDaySeries?: number;
  levelDetails?: {
    level: number;
    startPoints: number;
    endPoints: number;
    progress: number;
  };
  medals?: MedalUser[];
  emailNoCheck?: boolean;
  noGetUser?: boolean;
  acceptNotification?: boolean;
  position?: {
    type: "Point";
    coordinates: [0, 0];
  };
  placeName?: string;
  lastDateUpdateHealth?: string;
  preferredSports?: PreferredSports[];
  notificationMatch?: boolean;
  notificationNewGame?: boolean;
  notificationSportNews?: boolean;
  interestingPromo?: boolean;
  newFeature?: boolean;
  devicePushToken?: string;
  deviceId?: string;
  maximumDistance?: number;
  isCurrentLocation?: boolean;
  appleToken?: string;
  isGuest?: string;
  healthProvider?: string;
  playerCode?: string;
  showLevel?: boolean;
  appVersion?: string | null;
  isEnabledHealth?: boolean;
  finishHealthConnect?: boolean;
  lastOpenApp?: Date;
  isNewUser?: Date;
  statisticsPlayer?: StatisticsPlayer[];
  news?: NewsPopUp;
  emailGoogleHealth?: string;
}

export interface SingUpUser {
  email: string;
  password: string;
}
interface LoginUser {
  email: string;
  password: string;
}

export interface StatisticsPlayer {
  _id: string;
  loss: number;
  matches: number;
  sportId: string;
  wins: number;
}

export interface RatingJogga {
  id: string;
  type: string;
  viewType: string;
  rating: string;
  title: string;
  error: boolean;
  modeType: {
    value: string;
    subtitle: string;
  }[];
}
export interface ReviewUsProps {
  description: string;
  secondaryRating: RatingJogga[];
  rating: number;
}

export interface NewsPopUp {
  id: string;
  details: {
    lang: string;
    title: string;
    body: string;
  }[];
  detailsLocal?: {
    lang: string;
    title: string;
    body: string;
  };
  isActive: boolean;
  isUpdate: boolean;
  isForceUpdate: boolean;
  link: string;
}

const defaultUser: User = {
  _id: "",
  sendReviewApp: false,
  firstName: "",
  lastName: "",
  email: "",
  userLanguage: null,
  imageUser: "",
  imageUserCard: "",
  acceptNotification: false,
  emailNoCheck: true,
  position: {
    type: "Point",
    coordinates: [0, 0],
  },
  placeName: "",
  preferredSports: [],
  notificationMatch: true,
  notificationNewGame: true,
  interestingPromo: true,
  newFeature: true,
  maximumDistance: 100,
  statisticsPlayer: [],
  country: "",
};

export interface AuthModel {
  isLoading: boolean;
  checkEmail: boolean;
  reRendering: boolean;
  isAuthenticated: boolean;
  userLanguage: string | null;
  user: User;
  verifyEmail: string;
  setIsLoading: Action<AuthModel, boolean>;
  setCheckEmail: Action<AuthModel, boolean>;
  setReRendering: Action<AuthModel>;
  setUserLanguage: Action<AuthModel, string>;
  setUser: Action<AuthModel, User>;
  setUserProperty: Action<AuthModel, { property: string; value: any }>;
  setPreferredSports: Action<AuthModel, PreferredSports[]>;
  setIsAuthenticated: Action<AuthModel, boolean>;
  setVerifyEmail: Action<AuthModel, string>;
  signUp: Thunk<AuthModel, SingUpUser>;
  userLogin: Thunk<AuthModel, LoginUser>;
  forgotPassword: Thunk<AuthModel, { email: string }>;
  changePassword: Thunk<AuthModel, { code: string; newPassword: string }>;
  logout: Thunk<
    AuthModel,
    { isForce?: boolean; isGuest?: boolean; toActivate?: boolean }
  >;
  deleteAccount: Thunk<AuthModel>;
  verifyMail: Thunk<AuthModel, { code: string }>;
  sendMail: Thunk<AuthModel, { userId: string }>;
  getMyProfile: Thunk<
    AuthModel,
    { openApp: boolean; appVersion?: string } | undefined
  >;
  editUser: Thunk<AuthModel, User>;
  deleteCard: Thunk<AuthModel, { cardId: string }>;
  getCards: Thunk<AuthModel>;
  loginWithFacebook: Thunk<AuthModel, { token: string }>;
  loginWithGoogle: Thunk<AuthModel, { googleToken: string, clientId: string }>;
}

const auth: AuthModel = {
  isLoading: false,
  reRendering: false,
  userLanguage: null,
  checkEmail: false,
  isAuthenticated: false,
  user: defaultUser,
  verifyEmail: "",

  setIsLoading: action((state, payload) => {
    if (state.isLoading !== payload) {
      state.isLoading = payload;
    }
  }),

  setCheckEmail: action((state, payload) => {
    state.checkEmail = payload;
  }),

  setReRendering: action((state) => {
    state.reRendering = !state.reRendering;
  }),
  setUserLanguage: action((state, payload) => {
    state.userLanguage = payload;
  }),

  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setVerifyEmail: action((state, payload) => {
    state.verifyEmail = payload;
  }),
  setUserProperty: action((state, { property, value }) => {
    // state.user[property] = value;
  }),
  setPreferredSports: action((state, payload) => {
    state.user.preferredSports = payload;
  }),
  setIsAuthenticated: action((state, payload) => {
    state.isAuthenticated = payload;
  }),

  signUp: thunk(async (actions, payload, helpers) => {
    actions.setIsLoading(true);
    try {
      await api.post(AUTH.SIGN_UP, {
        deviceId: Date(),
        ...payload,
      });
      await actions.setCheckEmail(true);
      actions.setVerifyEmail(payload.email);
      history.navigate("/verify-email");
      actions.setIsLoading(false);
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      actions.setIsLoading(false);
      actions.setIsAuthenticated(false);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      if (error?.response?.data?.errorCodes === 17) {
        Toast("error", "Exist deja un cont creat cu acest email");
      } else {
        Toast("erro", "Te rugam incearca mai tarzi");
      }
    }
  }),

  userLogin: thunk(async (actions, payload) => {
    actions.setIsLoading(true);
    const body = { email: payload.email, password: payload.password };
    try {
      const { data } = await api.post(AUTH.LOGIN, body);
      await localStorage.setItem("refreshToken", data.refreshToken);
      await localStorage.setItem("accessToken", data.accessToken);
      const { data: user } = await api.get(AUTH.MY_PROFILE);
      actions.setUser(user);

      actions.setIsAuthenticated(true);
      actions.setUser(data.user);
      actions.setIsLoading(false);
      history.navigate("/profile?tab=0");
    } catch (error: any) {
      actions.setIsLoading(false);
      actions.setIsAuthenticated(false);
      Toast(
        "error",
        "Vă rugăm să vă verificați parola sau e-mailul și încercați din nou"
      );
    }
  }),

  forgotPassword: thunk(async (actions, payload) => {
    actions.setIsLoading(true);
    try {
      await api.post(AUTH.FORGOT_PASSWORD, payload);
      history.navigate("/change-password");
      actions.setIsLoading(false);
    } catch (error) {
      Toast("error", "A aparut o eroare, va rugam incercati mai tarziu!");
      actions.setIsLoading(false);
      return "error";
    }
    return "success";
  }),
  changePassword: thunk(async (actions, payload) => {
    actions.setIsLoading(true);
    try {
      await api.post(AUTH.CHANGE_PASSWORD, payload);
      history.navigate("/login");
      actions.setIsLoading(false);
    } catch (error) {
      Toast("error", "A aparut o eroare, va rugam incercati mai tarziu!");
      actions.setIsLoading(false);
      return "error";
    }

    return "success";
  }),

  logout: thunk(async (actions, { isForce, isGuest, toActivate }) => {
    actions.setIsLoading(true);

    try {
      if (!isForce) {
        await api.post(AUTH.LOGOUT);
        history.navigate("/");
      } else {
        history.navigate("/login");
      }
    } catch (error) {
      if (!isForce) {
        Toast("error", "Error");
      }
    }
    await localStorage.removeItem("refreshToken");
    await localStorage.removeItem("accessToken");
    actions.setUser(defaultUser);
    setTimeout(() => actions.setIsAuthenticated(false), 300);
    actions.setIsLoading(false);
  }),
  deleteAccount: thunk(async (actions) => {
    actions.setIsLoading(true);
    actions.setIsAuthenticated(false);
    try {
      await api.post(AUTH.DELETE_ACCOUNT);
    } catch (error) {
      Toast("error", "Error");
    }
    await localStorage.removeItem("refreshToken");
    await localStorage.removeItem("accessToken");
    // rootNavigationWithReset("Authentication", { screen: "Welcome" });
    actions.setIsLoading(false);
  }),

  verifyMail: thunk(async (actions, payload, helpers) => {
    actions.setIsLoading(true);

    try {
      const { data } = await api.post(AUTH.VERIFY_MAIL, {
        ...payload,
      });

      await localStorage.setItem("refreshToken", data.refreshToken);
      await localStorage.setItem("accessToken", data.accessToken);

      const { data: user } = await api.get(AUTH.MY_PROFILE);
      actions.setUser(user);

      actions.setIsAuthenticated(true);
      actions.setUser(data.user);
      actions.setIsLoading(false);
      history.navigate("/account");

      actions.setVerifyEmail("");

      return "success";
    } catch (error) {
      Toast("error", "Error");
      actions.setIsLoading(false);
      return "error";
    }
  }),

  sendMail: thunk(async (actions, payload) => {
    actions.setIsLoading(true);
    try {
      await api.post(AUTH.SEND_MAIL, payload);
      actions.setIsLoading(false);
    } catch (error) {
      Toast("error", "Error");
    }
    actions.setIsLoading(false);
  }),

  editUser: thunk(async (actions, payload) => {
    actions.setIsLoading(true);
    try {
      if (payload.imageUser) {
        const data = new FormData();

        data.append("photo", payload.imageUser);
        const token = await localStorage.getItem("accessToken");
        await axios.post(process.env.REACT_APP_API + AUTH.PROFILE_PHOTO, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      }
      delete payload.imageUser;
      await api.put(AUTH.MY_PROFILE, payload);
      const { data } = await api.get(AUTH.MY_PROFILE);
      actions.setUser(data);
    } catch (error) {
      Toast("error", "Error");
    }
    actions.setIsLoading(false);
  }),

  getMyProfile: thunk(async (actions, payload) => {
    try {
      let config = {};
      if (payload) {
        config = {
          params: payload,
        };
      }

      const { data } = await api.get(AUTH.MY_PROFILE, config);
      actions.setUser(data);
      return data;
    } catch (error) {
      Toast("error", "Error");
    }
  }),

  getCards: thunk(async () => {
    try {
      const { data } = await api.get(AUTH.USER_CARDS);
      return data;
    } catch (error) {
      Toast("error", "Error");
    }
  }),
  deleteCard: thunk(async (_, payload) => {
    try {
      await api.post(AUTH.DELETE_CARDS, payload);
    } catch (error) {
      Toast("error", "Error");
    }
  }),
  loginWithFacebook: thunk(async (actions, payload) => {
    actions.setIsLoading(true);
    try {
      const { data } = await api.post(AUTH.LOGIN_FACEBOOK, {
        facebookToken: payload.token,
        deviceId: Date.now(),
      });
      await localStorage.setItem("refreshToken", data.refreshToken);
      await localStorage.setItem("accessToken", data.accessToken);
      const { data: user } = await api.get(AUTH.MY_PROFILE);
      actions.setUser(user);

      actions.setIsAuthenticated(true);
      actions.setUser(data.user);
      actions.setIsLoading(false);
      history.navigate("/profile?tab=0");
    } catch (error: any) {
      actions.setIsLoading(false);
      actions.setIsAuthenticated(false);
      Toast(
          "error",
          "Vă rugăm să vă verificați parola sau e-mailul și încercați din nou"
      );
    }
  }),
  loginWithGoogle: thunk(async (actions, payload) => {
    actions.setIsLoading(true);

    try {
      const { data } = await api.post(AUTH.LOGIN_GOOGLE, {
        ...payload,
        deviceId: Date.now(),
      });
      await localStorage.setItem("refreshToken", data.refreshToken);
      await localStorage.setItem("accessToken", data.accessToken);
      const { data: user } = await api.get(AUTH.MY_PROFILE);
      actions.setUser(user);

      actions.setIsAuthenticated(true);
      actions.setUser(data.user);
      actions.setIsLoading(false);
      history.navigate("/profile?tab=0");
    } catch (error: any) {
      actions.setIsLoading(false);
      actions.setIsAuthenticated(false);
      Toast(
          "error",
          "Vă rugăm să vă verificați parola sau e-mailul și încercați din nou"
      );
    }
  }),
};

export default auth;
