import React from "react";
import { FC } from "react";



const ArrowDown: FC = () => {
  return (
      <span
          className=" absolute z-10 w-15 h-15 lg:w-6 lg:h-6 text-sm flex items-center justify-center right-1 lg:right-3 top-1/2 transform -translate-y-1/2"
      >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#fff" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
              </svg>
            </span>
  );
};

export default ArrowDown;
