interface AuthRoutes {
  LOGIN_GOOGLE: string;
  LOGIN_GOOGLE_FIT: string;
  DISCONNECT_HEALTH: string;
  LOGIN_FACEBOOK: string;
  LOGIN_APPLE: string;
  LOGOUT: string;
  DELETE_ACCOUNT: string;
  LOGIN: string;
  DIRECT_LOGIN: string;
  FORGOT_PASSWORD: string;
  CHANGE_PASSWORD: string;
  SIGN_UP: string;
  REGISTER_GUEST: string;
  USER_BY_DEVICE_ID: string;
  VERIFY_MAIL: string;
  SEND_MAIL: string;
  REFRESH_TOKEN: string;
  MY_PROFILE: string;
  LOGIN_APPLE_HEALTH: string;
  APPLE_HEALTH: string;
  CHECK_PHONE: string;
  SEND_SMS: string;
  PHONE_CALL: string;
  USER_CARDS: string;
  DELETE_CARDS: string;
  REVIEW_US: string;
  REVIEW_OPTIONS: string;
  POINTS_INFO: string;
  PROFILE_PHOTO: string;
}
export const AUTH: AuthRoutes = {
  LOGIN_GOOGLE: "/users/login-google",
  LOGIN_GOOGLE_FIT: "/users/login-google-fit",
  LOGIN_APPLE_HEALTH: "users/login-apple-health",
  APPLE_HEALTH: "/users/apple-health",
  DISCONNECT_HEALTH: "/users/disconnect-health",
  LOGIN_FACEBOOK: "/users/login-facebook",
  LOGIN_APPLE: "/users/login-apple",
  LOGOUT: "/users/logout",
  LOGIN: "/users/login-password",
  DELETE_ACCOUNT: "/users/delete-account",
  DIRECT_LOGIN: "/users/direct-login",
  FORGOT_PASSWORD: "users/forgot-password",
  CHANGE_PASSWORD: "users/password-reset",
  SIGN_UP: "/users/register",
  REGISTER_GUEST: "/users/register/guest",
  USER_BY_DEVICE_ID: "/users/check-by-device-id",
  VERIFY_MAIL: "users/register/check-email",
  SEND_MAIL: "users/register/send-email",
  REFRESH_TOKEN: "/users/refresh-token",
  MY_PROFILE: "/users/my-profile",
  SEND_SMS: "/users/phone/send-sms",
  CHECK_PHONE: "/users/phone/check",
  PHONE_CALL: "/users/phone/call",
  USER_CARDS: "/users/cards",
  DELETE_CARDS: "/users/cards/delete",
  REVIEW_US: "/users/review",
  REVIEW_OPTIONS: "/users/review-options",
  POINTS_INFO: "/users/point-info",
  PROFILE_PHOTO: "/users/profile-photo",
};

interface GamesRoutes {
  NEARBY_GAMES: string;
  NEARBY_GAMES_GAME_ID: string;
  REVIEW_GAMES_GAME: string;
  MATCHES_TEAMS: string;
  MY_GAMES: string;
  ATTEND_GAME: string;
  ACCEPT_ATTEND: string;
  INVITATION_GAME: string;
  PLAYER_INVITATIONS_BY_GAME: string;
  SEEN_INVITE: string;
  MATCHES: string;
  MATCHES_COMPETITION: string;
  MATCHES_START: string;
  MATCHES_STOP: string;
  MATCHES_WIN: string;
  MATCHES_FINISH: string;
  GROUP_GAMES: string;
  KICK_OUT_GAME: string;
  PLAYER_GUESTS: string;
  PLAYER_GUESTS_BY_GAME: string;
  GAMES_ALL_TEAMS: string;
  MY_NEXT_GAME: string;
  RECOMMENDED_GAMES: string;
  CHECK_CREATE_GAMES: string;
  GET_GROUP_GAME_SCHEDULER: string;
  MATCH_SCORE: string;
  GAME_INVITATION: string;
}
export const GAMES: GamesRoutes = {
  NEARBY_GAMES: "/games/nearby",
  NEARBY_GAMES_GAME_ID: "/games/nearby/:gameId",
  REVIEW_GAMES_GAME: "/games/review",
  MY_GAMES: "/games/my-games",
  CHECK_CREATE_GAMES: "/games/create/check",
  MY_NEXT_GAME: "/games/my-games/next",
  ATTEND_GAME: "/games/attend/:gameId",
  ACCEPT_ATTEND: "/games/attend/accept",
  INVITATION_GAME: "/games/invitation/:gameId",
  PLAYER_INVITATIONS_BY_GAME: "/games/invitation/:gameId/players",
  SEEN_INVITE: "games/invitation/seen",
  MATCHES_TEAMS: "games/matches/teams",
  MATCHES: "games/matches",
  MATCHES_COMPETITION: "games/matches/competition",
  MATCHES_START: "games/matches/start",
  MATCHES_STOP: "games/matches/stop",
  MATCHES_WIN: "games/matches/win",
  MATCHES_FINISH: "games/matches/finish",
  GROUP_GAMES: "/games/group/:groupId",
  KICK_OUT_GAME: "/games/out",
  PLAYER_GUESTS: "/games/guests",
  PLAYER_GUESTS_BY_GAME: "/games/guests/:gameId",
  GAMES_ALL_TEAMS: "/games/matches/teams/:gameId",
  RECOMMENDED_GAMES: "/games/recommended",
  GET_GROUP_GAME_SCHEDULER: "/games/group/scheduler",
  MATCH_SCORE: "games/matches/score",
  GAME_INVITATION: "games/invitation/received/:gameId",
};

interface LocationsRoutes {
  NEARBY_LOCATIONS: string;
  NEARBY_LOCATION_ID: string;
  REGISTER_LOCATION: string;
  SEARCH_LOCATIONS: string;
  LOCATION_BOOKS: string;
  MY_BOOKINGS: string;
  QRCODE_BOOKING: string;
  BOOKING_CANCEL_PAYMENT: string;
  BOOKING_CANCEL: string;
  BOOK_ID: string;
  DEALS: string;
  NEXT_BOOKINGS: string;
  NEARBY_EVENTS: string;
  NEARBY_EVENTS_DETAILS: string;
  NEARBY_EVENTS_ATTEND: string;
}
export const LOCATIONS: LocationsRoutes = {
  NEARBY_LOCATIONS: "/locations/nearby",
  NEARBY_LOCATION_ID: "/locations/:locationId",
  REGISTER_LOCATION: "/partners/auth/register/application",
  SEARCH_LOCATIONS: "/locations/search",
  LOCATION_BOOKS: "/locations/books",
  QRCODE_BOOKING: "/locations/books/qrcode",
  MY_BOOKINGS: "/locations/my-bookings",
  BOOKING_CANCEL_PAYMENT: "/locations/books/cancel-payment",
  BOOKING_CANCEL: "/locations/books/cancel",
  BOOK_ID: "/locations/books/:bookID",
  DEALS: "/locations/deals",
  NEXT_BOOKINGS: "/locations/books/next",
  NEARBY_EVENTS: "/locations/events/nearby",
  NEARBY_EVENTS_DETAILS: "/locations/events/:eventId",
  NEARBY_EVENTS_ATTEND: "/locations/events/attend",
};

interface PlayersRoutes {
  FAVORITE_ADDRESS: string;
  FAVORITE_ADDRESS_ADDRESS_ID: string;
  PLAYERS_GROUPS: string;
  PLAYERS_GROUPS_ATTEND: string;
  PLAYERS_GROUPS_ACCEPT: string;
  PLAYERS_GROUPS_ID: string;
  NEARBY_PLAYERS: string;
  NEARBY_PLAYERS_PLAYER_ID: string;
  MESSAGE_SEND: string;
  MESSAGE_GAME: string;
  MESSAGE_GROUP: string;
  MESSAGE_USER: string;
  STATUS: string;
  NOTIFICATIONS: string;
  FRIENDS_INVITE: string;
  SEEN_INVITE: string;
  FRIENDS_INVITE_CHANGE: string;
  INVITATION_EXTERN: string;
  SEARCH_PLAYER: string;
  SEARCH_GROUPS: string;
  TOGGLE_BLOCK: string;
  KICK_OUT_GROUP: string;
  REPORT_PLAYER: string;
  CHAT_MUTE: string;
  FINISH_LEADERBOARD: string;
  RECOMMENDED_GROUPS: string;
  SPORT_NEWS: string;
  EDIT_GROUPS_ROLE: string;
  VIDEO_PRESENTATION: string;
  MEDAL_DETAILS: string;
  MEDALS: string;
}

export const PLAYERS: PlayersRoutes = {
  FAVORITE_ADDRESS: "/players/favorite-address",
  FAVORITE_ADDRESS_ADDRESS_ID: "/players/favorite-address/:addressID",
  PLAYERS_GROUPS: "/players/groups",
  PLAYERS_GROUPS_ATTEND: "/players/groups/attend",
  PLAYERS_GROUPS_ACCEPT: "/players/groups/accept",
  PLAYERS_GROUPS_ID: "/players/groups/:groupId",
  CHAT_MUTE: "/players/toggle/mute",
  NEARBY_PLAYERS: "/players/nearby",
  NEARBY_PLAYERS_PLAYER_ID: "/players/nearby/:playerId",
  MESSAGE_SEND: "/players/message/send",
  MESSAGE_GAME: "/players/message/game/:toGameId",
  MESSAGE_USER: "/players/message/user/:toUserId",
  MESSAGE_GROUP: "/players/message/group/:toGroupId",
  STATUS: "/players/status",
  NOTIFICATIONS: "/players/status/details",
  FRIENDS_INVITE: "/players/friends/invite",
  SEEN_INVITE: "players/friends/invite/seen",
  FRIENDS_INVITE_CHANGE: "/players/friends/invite/change-status",
  INVITATION_EXTERN: "players/friends/invite/extern",
  SEARCH_PLAYER: "/players/search",
  SEARCH_GROUPS: "/players/groups/search",
  EDIT_GROUPS_ROLE: "/players/groups/role",
  KICK_OUT_GROUP: "/players/groups/out",
  TOGGLE_BLOCK: "/players/toggle/block",
  REPORT_PLAYER: "/players/report",
  FINISH_LEADERBOARD: "/players/groups/leaderboard/finish",
  RECOMMENDED_GROUPS: "/players/groups/recommended",
  SPORT_NEWS: "/players/sport/news",
  VIDEO_PRESENTATION: "/players/video-presentation",
  MEDAL_DETAILS: "/players/medals/:medalId",
  MEDALS: "/players/medals",
};
interface CoachesRoutes {
  NEARBY_COACH_BY_ID: string;
  NEARBY_COACHES: string;
  SEARCH_COACHES: string;
}
export const COACHES: CoachesRoutes = {
  NEARBY_COACH_BY_ID: "/coaches/nearby/:coachId",
  NEARBY_COACHES: "/coaches/nearby",
  SEARCH_COACHES: "/coaches/search",
};

interface CompetitionsRoutes {
  DAY_SERIES: string;
  CHALLENGE: string;
  CHALLENGE_HISTORY: string;
  SERIES_HISTORY: string;
  LEADERBOARD: string;
  CHALLENGE_PLAYER: string;
}
export const COMPETITIONS: CompetitionsRoutes = {
  DAY_SERIES: "/competitions/series",
  CHALLENGE: "/competitions/challenge",
  CHALLENGE_HISTORY: "/competitions/challenge/history",
  SERIES_HISTORY: "/competitions/series/history",
  CHALLENGE_PLAYER: "/competitions/challenge/player",
  LEADERBOARD: "/competitions/leaderboard/:type",
};

interface StarsRoutes {
  NEARBY_STARS_BY_ID: string;
  NEARBY_STARS: string;
  SEARCH_STARS: string;
}
export const STARS: StarsRoutes = {
  NEARBY_STARS_BY_ID: "/stars/nearby/:coachId",
  NEARBY_STARS: "/stars/nearby",
  SEARCH_STARS: "/stars/search",
};

interface PaymentsRoutes {
  PAYMENT_INTENT_LOCATION: string;
}
export const PAYMENTS: PaymentsRoutes = {
  PAYMENT_INTENT_LOCATION: "/payments/intent/location",
};
