import React, {useEffect, useRef, useState} from "react";
import direction from "images/direction.png";
import calendarImg from "images/calendar.png";
import call from "images/call.png";
import moment from "moment";
import {useParams} from "react-router-dom";
import { atcb_action, atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import api from "../../api";
import {LOCATION} from "../../api/apiRoutes";
import urlParamReplacer from "../../utils/urlParamReplacer";

const PageSmsBooking = () => {
  let { id } = useParams();
  const [locationPayment, setLocationPayment] = useState<any>()
  const [bookingExpireDate, setBookingExpireDate] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [htmlPay, setHtmlPay] = useState("")
  const interval = useRef()
  useEffect(() => {
    if(id){
      getReservationData()
    }
    return () => {
      if(interval.current){
        clearInterval(interval.current)
      }
    }
  }, [id])
  // useEffect(() => atcb_init());

  const getReservationData = async () => {
    setIsLoading(true)
    try {
      const { data } = await api.get(urlParamReplacer(LOCATION.GET_LOCATION_BOOKINGS, id));
      if(data){
        setLocationPayment(data)
        setBookingExpireDate(moment(data.createdAt).add(2, "h"))
        // @ts-ignore
        interval.current = setInterval(() => {
          setBookingExpireDate(moment(data.createdAt).add(2, "h"))
        }, 60000)
      }

    } catch (e) {
      setError(true)
      console.log(e)
    }
    setIsLoading(false)
  }

  const openPay = async () => {
    try {
      const { data } = await api.post(`/locations/books/pay`, {
        bookId: id
      });
      setHtmlPay(data)
      setTimeout(() => {
        if(document){ // @ts-ignore
          document.getElementById("paymentForm").submit();
        }

      }, 300)
      console.log(data)
    } catch (e) {
      setError(true)
      console.log(e)
    }
  }

  return (
      <div className="pageReservation">
        {isLoading ? (
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
        ): locationPayment ? (
            <div className="pageReservation">
              <div className="cardReservation">
                <img
                    src={locationPayment?.location?.imageUrl}
                    width="500"
                    height="300"
                    className="imageLocationCard"
                    alt="logo"
                />

              <div style={{
                flex: 1
              }}>
                <p className="titleCardReservation">{locationPayment.location.name}</p>

                <div className={"mt-5"}>
                  <p className={"textCenter mb-1 bold colorBlack"}>Rezervare</p>
                  <div className="cardFlexContainer">
                    <div className="flex1">
                      <p className="text-center colorBlack bold text-xl">{moment(locationPayment.startDateString).format('dddd DD MMM HH:mm')}</p>
                      <p className="text-center colorBlack text-l">
                        {moment(locationPayment.startDateString).format("HH:mm")}
                        {" la " }
                        {moment(locationPayment.endDateString).format("HH:mm")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cardFlexContainer mt-5 flex1">
                  <div className="flex1">
                  <a href={`https://www.google.com/maps/dir//${locationPayment.location.position.coordinates[1]},${locationPayment.location.position.coordinates[0]}/@${locationPayment.location.position.coordinates[1]},${locationPayment.location.position.coordinates[0]},19.02z`}
                     target="_blank">
                    <div className="flex1">
                      <div className="flexCenterImage">
                        <img src={direction} height={30} width={30}/>
                      </div>
                      <p className="textCenter colorBlack m-3 text-sm">Harta</p>
                    </div>
                  </a>
                  </div>
                  <div className="flex1">
                    <a href={`tel:${locationPayment.location.phone}`}>
                      <div className="flexCenterImage">
                        <img src={call} height={30} width={30}/>
                      </div>
                      <p className="textCenter colorBlack m-3 text-sm">Suna</p>
                    </a>
                  </div>
                  <div className="flex1">
                    <a onClick={(e) => {
                      e.preventDefault();
                      atcb_action({
                        name: `Rezervare teren ${locationPayment.location.name}`,
                        startDate: locationPayment.startDateString,
                        endDate: locationPayment.endDateString,
                        options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
                        timeZone: "Europe/Bucharest",
                        iCalFileName: "Reminder-Event",
                      });
                    }}>
                      <div className="flexCenterImage">
                        <img src={calendarImg} height={30} width={30}/>
                      </div>
                      <p className="textCenter colorBlack mt-3 text-sm">Calendar</p>
                    </a>
                  </div>
                </div>
                {locationPayment.status === 1  ? (
                    <div className="cardFlexContainer margin-vertical-m">
                      <div className="flex1 flexCenterImage">
                        <button onClick={() => openPay()} style={{
                          width: 200,
                          padding: 10,
                          borderRadius: 20,
                          backgroundColor: "#48c348",
                          borderWidth: 0
                        }}>
                          <p className="textCenter" style={{
                            color: "#fff",
                            fontSize: 16,
                            fontWeight: "bold"
                          }}>Plateste {locationPayment.priceTotal } lei </p>
                        </button>
                      </div>
                      <div className="content" dangerouslySetInnerHTML={{__html: htmlPay}}></div>
                    </div>
                ) : null}
                {locationPayment.status === 3 && locationPayment.priceTotalAdvance && !locationPayment.transactionComplete  ? (
                    <div className="cardFlexContainer margin-vertical-m">
                      <div className="flex1 flexCenterImage">
                        <button onClick={() => openPay()} style={{
                          width: 200,
                          padding: 10,
                          borderRadius: 20,
                          backgroundColor: "#48c348",
                          borderWidth: 0
                        }}>
                          <p className="textCenter" style={{
                            color: "#fff",
                            fontSize: 16,
                            fontWeight: "bold"
                          }}>Plateste avans {locationPayment.priceTotalAdvance} lei</p>
                        </button>
                      </div>
                      <div className="content" dangerouslySetInnerHTML={{__html: htmlPay}}></div>
                    </div>
                ) : null}
                {locationPayment.status === 1 || (locationPayment.status === 3 && locationPayment.priceTotalAdvance && !locationPayment.transactionComplete) ? (
                    <div className="cardFlexContainer">
                      <div className="flex1 waiting rounded-[10px]">
                        <p className="textCenter colorWhite text-sm">Status: in asteptare plata</p>
                        <p className="textCenter colorWhite text-sm">Rezervarea expira in </p>
                        <p className="expirationBookings">{bookingExpireDate.diff(moment(), "m")} de minute</p>
                        <p className="textCenter colorWhite text-sm">Apasa plateste si finalizeaza rezervarea</p>
                      </div>
                    </div>
                ) : locationPayment.status === 4 || (locationPayment.status === 3 && locationPayment.transactionComplete) ? (
                    <div className="cardFlexContainer">
                      <div className="flex1 approve rounded-[10px]">
                        {locationPayment.priceTotalAdvance ? (
                            <p className="textCenter colorWhite">Status: Plata avans {locationPayment.priceTotalAdvance} lei cu succes</p>
                        ): (
                            <p className="textCenter colorWhite">Status: Plata cu succes</p>
                        )}

                      </div>
                    </div>
                ) : (
                    <div className="cardFlexContainer">
                      <div className="flex1 rejected rounded-[10px]">
                        <p className="textCenter colorWhite">Status: {locationPayment.refunded ? "Rezervare anulata plata rambursata" : "Rezervarea a expirat"}</p>
                      </div>
                    </div>
                )}

              </div>
              </div>
            </div>
        ) : error ? (
            <div className="pageReservation">
              <div className="cardReservation">
                <p className="textCenter"> Eroare</p>
                <p className="textCenter">Opss, am întâmpinat o eroare, încercați din nou mai târziu sau contactați locația în care ați făcut rezervarea.</p>
              </div>

            </div>
        ) : null}
      </div>
  )
}

export default PageSmsBooking;
