export const LOCATION = {
  GET_LOCATIONS: "/locations/web",
  GET_LOCATIONS_CITY: "/locations/web/city",
  GET_LOCATION_DETAILS: "/locations/seo/:seoUrl",
  GET_LOCATION_BOOKINGS: "/locations/books/:bookingId",
  LOCATION_BOOKINGS: "/locations/books/web",
  MY_BOOKINGS: "/locations/my-bookings",
  FAVORITE_ADDRESS: "/players/favorite-address",
}

export const AUTH = {
  LOGIN_GOOGLE: "/users/login-google",
  LOGIN_GOOGLE_FIT: "/users/login-google-fit",
  LOGIN_APPLE_HEALTH: "users/login-apple-health",
  APPLE_HEALTH: "/users/apple-health",
  DISCONNECT_HEALTH: "/users/disconnect-health",
  LOGIN_FACEBOOK: "/users/login-facebook",
  LOGIN_APPLE: "/users/login-apple",
  LOGOUT: "/users/logout",
  LOGIN: "/users/login-password",
  DELETE_ACCOUNT: "/users/delete-account",
  DIRECT_LOGIN: "/users/direct-login",
  FORGOT_PASSWORD: "users/forgot-password",
  CHANGE_PASSWORD: "users/password-reset",
  SIGN_UP: "/users/register",
  REGISTER_GUEST: "/users/register/guest",
  USER_BY_DEVICE_ID: "/users/check-by-device-id",
  VERIFY_MAIL: "users/register/check-email",
  SEND_MAIL: "users/register/send-email",
  REFRESH_TOKEN: "/users/refresh-token",
  MY_PROFILE: "/users/my-profile",
  SEND_SMS: "/users/phone/send-sms",
  CHECK_PHONE: "/users/phone/check",
  PHONE_CALL: "/users/phone/call",
  USER_CARDS: "/users/cards",
  DELETE_CARDS: "/users/cards/delete",
  REVIEW_US: "/users/review",
  REVIEW_OPTIONS: "/users/review-options",
  POINTS_INFO: "/users/point-info",
  PROFILE_PHOTO: "/users/profile-photo",
};
