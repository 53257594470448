import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl pb-5">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Politică de confidențialitate
          </h1>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p>
          Am definit această politică de confidențialitate pentru a explica și
          descrie datele personale pe care le colectăm și în ce scopuri, precum
          și drepturile dvs. de confidențialitate și modul în care le puteți
          exercita.
        </p>
        <p>
          Această politică de confidențialitate se aplică utilizării datelor
          noastre personale colectate de noi sau furnizate de dvs. în legătură
          cu utilizarea aplicației mobile.
        </p>
        <p>
          Politica de confidențialitate poate fi revizuită pentru a o menține în
          conformitate cu modificările din activitatea noastră, serviciile sau
          legislația aplicabilă. Toate modificările aduse acestei politici de
          confidențialitate vor fi publicate pe site-ul și aplicația noastră. În
          cazul unor modificări substanțiale, vom informa și utilizatorii noștri
          prin e-mail sau prin alte mijloace.
        </p>
        <h3>
          <strong>Ce date sunt colectate despre mine?</strong>
        </h3>
        <p>
          Colectăm și prelucrăm toate datele personale pe care alegeți să ni le
          comunicați atunci când vă înregistrați pentru a utiliza serviciile
          noastre, cum ar fi: numele, adresa de email, setări de limbă, fus
          orar. De asemenea, colectăm și procesăm automat anumite tipuri de
          informații, ori de câte ori interacționați cu noi pentru a vă permite
          să utilizați serviciile noastre (de exemplu, urmărirea locației dvs.
          în timpul utilizării pentru a vă arăta utilizatorii aflați în
          apropiere de dumneavoastră).
        </p>
        <h3>
          <strong>Cu ce scop prelucrăm datele personale?</strong>
        </h3>
        <p>
          Colectăm si prelucrăm date cu caracter personal ori de câte ori este
          nevoie pentru operarea, furnizarea sau îmbunătățirea serviciilor
          noastre. Vă vom solicita întotdeauna consimțământul prealabil înainte
          de a prelucra datele dvs. personale în orice alt scop.
        </p>
        <h3>
          <strong>Sunt datele mele partajate cu terți?</strong>
        </h3>
        <p>
          Datele dvs. pot fi transferate doar către un destinatar autorizat și
          ne vom asigura că aceste date sunt transferate în conformitate cu
          prezenta politică de confidențialitate și cu regulamentul general
          privind protecția datelor.
        </p>
        <h3>
          <strong>Sunt datele mele în siguranță?</strong>
        </h3>
        <p>
          Datele dvs. personale sunt stocate în siguranță. Compania folosește
          garanții de securitate fizice, administrative și software pentru a
          oferi o protecție rezonabilă pentru datele dvs.
        </p>
        <h3>
          <strong>
            Cine este responsabil pentru prelucrarea datelor cu caracter
            personal?
          </strong>
        </h3>
        <p>
          Controlorul datelor cu caracter personal este WEVIEW TECH SRL, St.
          Stefan Octavian Iosif, Busteni, Romania, Cod fiscal: 42225653, Nr.
          inregistrare: J29/246/2020
        </p>

        <h3>
          <strong>Cât timp sunt păstrate datele mele personale?</strong>
        </h3>
        <p>
          Compania păstrează datele personale doar pentru timpul strict necesar
          pentru a permite utilizarea serviciilor noastre și / sau pentru a
          îndeplini scopurile identificate mai sus și / sau pentru a respecta
          orice obligații legale sau de reglementare.
        </p>
        <h3>
          <strong>Cât timp sunt păstrate datele mele personale?</strong>
        </h3>
        <p>
          Sub rezerva limitărilor prevăzute de legislația europeană și orice
          altă lege aplicabilă, aveți dreptul de acces, rectificare, restricție,
          opoziție, ștergere și portabilitate a datelor. Aceste drepturi pot fi
          exercitate contactându-ne prin e-mail (office.jogga@gmail.com)
        </p>
        <p>
          Dacă nu sunteți mulțumit de modul în care abordăm problemele de
          confidențialitate, puteți depune o reclamație la autoritatea de
          supraveghere română pentru protecția datelor sau la autoritatea
          națională de supraveghere pentru protecția datelor în țara dvs. de
          reședință.
        </p>
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 pb-10 ">
      <Helmet>
        <title>Politică de confidențialitate</title>
      </Helmet>
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
